/**
 * @fileoverview gRPC-Web generated client stub for financial
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v3.21.12
// source: financial/fixedRateBondCalculator.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js')

var financial_fixedRateBondStub_pb = require('../financial/fixedRateBondStub_pb.js')

var financial_couponPaymentStub_pb = require('../financial/couponPaymentStub_pb.js')

var financial_maturityPaymentStub_pb = require('../financial/maturityPaymentStub_pb.js')

var ledger_amount_pb = require('../ledger/amount_pb.js')
const proto = {};
proto.financial = require('./fixedRateBondCalculator_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.financial.FixedRateBondCalculatorClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.financial.FixedRateBondCalculatorPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.financial.CalculateFixedRateBondRequest,
 *   !proto.financial.CalculateFixedRateBondResponse>}
 */
const methodDescriptor_FixedRateBondCalculator_CalculateFixedRateBond = new grpc.web.MethodDescriptor(
  '/financial.FixedRateBondCalculator/CalculateFixedRateBond',
  grpc.web.MethodType.UNARY,
  proto.financial.CalculateFixedRateBondRequest,
  proto.financial.CalculateFixedRateBondResponse,
  /**
   * @param {!proto.financial.CalculateFixedRateBondRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.financial.CalculateFixedRateBondResponse.deserializeBinary
);


/**
 * @param {!proto.financial.CalculateFixedRateBondRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.financial.CalculateFixedRateBondResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.financial.CalculateFixedRateBondResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.financial.FixedRateBondCalculatorClient.prototype.calculateFixedRateBond =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/financial.FixedRateBondCalculator/CalculateFixedRateBond',
      request,
      metadata || {},
      methodDescriptor_FixedRateBondCalculator_CalculateFixedRateBond,
      callback);
};


/**
 * @param {!proto.financial.CalculateFixedRateBondRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.financial.CalculateFixedRateBondResponse>}
 *     Promise that resolves to the response
 */
proto.financial.FixedRateBondCalculatorPromiseClient.prototype.calculateFixedRateBond =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/financial.FixedRateBondCalculator/CalculateFixedRateBond',
      request,
      metadata || {},
      methodDescriptor_FixedRateBondCalculator_CalculateFixedRateBond);
};


module.exports = proto.financial;

