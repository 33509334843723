import React, { useRef, useState } from "react";
import DownloadIcon from "@mui/icons-material/Download";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import { FixedRateForm } from "./components/FixedRateForm";
import { FloatingRateForm } from "./components/FloatingRateForm";
import {
  Box,
  Button,
  Card,
  CardContent,
  IconButton,
  MenuItem,
  TextField,
  Tooltip,
} from "@mui/material";
import { Timezone } from "@mesh/common-js/dist/i8n/timezone_pb";
import {
  allTimezones,
  timezoneToString,
} from "@mesh/common-js/dist/i8n/timezone";
import { Timestamp } from "google-protobuf/google/protobuf/timestamp_pb";
import { dayjsToProtobufTimestamp } from "@mesh/common-js/dist/googleProtobufConverters";
import utc from "dayjs/plugin/utc";
import dayjs from "dayjs";
import { DateTimeField } from "@mesh/common-js-react/dist/FormFields";
import { Info as InfoIcon } from "@mui/icons-material";
dayjs.extend(utc);

enum InstrumentType {
  FloatingRateBond = "Floating Rate Bond",
  FixedRateBond = "Fixed Rate Bond",
}

const allInstrumentTypes: InstrumentType[] = [
  InstrumentType.FloatingRateBond,
  InstrumentType.FixedRateBond,
];

const instrumentTypePathOptions: {
  instrumentType: InstrumentType;
  path: string;
}[] = [
  {
    instrumentType: InstrumentType.FixedRateBond,
    path: "/instrument-calculator/fixed-rate-bond",
  },
  {
    instrumentType: InstrumentType.FloatingRateBond,
    path: "/instrument-calculator/floating-rate-bond",
  },
];

export const InstrumentCalculator = () => {
  const navigate = useNavigate();
  const [openForm, setOpenForm] = useState(true);
  const exportToExcelFunctionRef = useRef<() => void>(() => null);
  const calculateFunc = useRef<() => void>(() => null);
  const resetFunc = useRef<() => void>(() => null);
  const [timezone, setTimezone] = useState<Timezone>(Timezone.SAST_TIMEZONE);
  const [evaluationTime, setEvaluationTime] = useState<Timestamp>(
    dayjsToProtobufTimestamp(dayjs().utc(false)),
  );

  const activeInstrumentType = instrumentTypePathOptions.find((tpo) =>
    window.location.pathname.includes(tpo.path),
  );
  if (!activeInstrumentType) {
    return <Navigate to={instrumentTypePathOptions[0].path} />;
  }

  return (
    <Box sx={{ display: "flex", justifyContent: "center", p: 2 }}>
      <Card sx={{ width: "100%" }}>
        <CardContent
          sx={{
            textAlign: "start",
          }}
        >
          <Box
            sx={(theme) => ({
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: theme.spacing(1),
            })}
          >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <TextField
                label="Timezone"
                select
                value={timezone}
                onChange={(e) => setTimezone(Number(e.target.value))}
              >
                {allTimezones
                  .filter((tz) => tz != Timezone.UNDEFINED_TIMEZONE)
                  .map((tz, idx) => (
                    <MenuItem key={idx} value={tz}>
                      {timezoneToString(tz)}
                    </MenuItem>
                  ))}
              </TextField>
              <Tooltip title="The timezone in which times will be shown on the form. All timezones stored in UTC.">
                <InfoIcon sx={{ ml: 1 }} />
              </Tooltip>
            </Box>

            <Box sx={{ display: "flex", alignItems: "center" }}>
              <DateTimeField
                label="Evaluation Time"
                nullable
                timezone={timezone}
                value={evaluationTime}
                onChange={(newValue) =>
                  setEvaluationTime(newValue ?? new Timestamp())
                }
              />
              <Tooltip title="The timezone at which the instrument calculations are done. Most relevant for instrument pricing and rate resets.">
                <InfoIcon sx={{ ml: 1 }} />
              </Tooltip>
            </Box>

            <TextField
              label="Instrument Type"
              select
              value={activeInstrumentType.instrumentType}
              onChange={(e) => {
                const opt = instrumentTypePathOptions.find(
                  (ito) => ito.instrumentType === e.target.value,
                );
                if (!opt) {
                  return;
                }
                navigate(opt.path);
              }}
            >
              {allInstrumentTypes.map((instrumentType, idx) => (
                <MenuItem key={idx} value={instrumentType}>
                  {instrumentType}
                </MenuItem>
              ))}
            </TextField>

            <Box
              sx={(theme) => ({
                marginLeft: "auto",
                display: "flex",
                alignItems: "center",
                gap: theme.spacing(1),
              })}
            >
              <Button
                variant="contained"
                color="primary"
                onClick={() => calculateFunc.current()}
              >
                Calculate
              </Button>
              <Button variant="outlined" onClick={() => resetFunc.current()}>
                Reset
              </Button>
              <IconButton onClick={() => setOpenForm(!openForm)}>
                {openForm ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
              <IconButton onClick={() => exportToExcelFunctionRef.current()}>
                <DownloadIcon />
              </IconButton>
            </Box>
          </Box>

          <Box sx={{ width: "100%", py: 2 }}>
            <Routes>
              <Route index element={<Navigate to="fixed-rate-bond" />} />
              <Route
                path="fixed-rate-bond"
                element={
                  <FixedRateForm
                    calculateFunc={calculateFunc}
                    resetFunc={resetFunc}
                    timezone={timezone}
                    evaluationTime={evaluationTime}
                    exportExcelFunc={exportToExcelFunctionRef}
                    openForm={openForm}
                  />
                }
              />
              <Route
                path="floating-rate-bond"
                element={
                  <FloatingRateForm
                    calculateFunc={calculateFunc}
                    resetFunc={resetFunc}
                    timezone={timezone}
                    evaluationTime={evaluationTime}
                    exportExcelFunc={exportToExcelFunctionRef}
                    openForm={openForm}
                  />
                }
              />
              <Route path="*" element={<Navigate to="" />} />
            </Routes>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};
