import React, { useEffect, useState, useCallback, useMemo } from "react";
import {
  Box,
  Collapse,
  FormControlLabel,
  MenuItem,
  Typography,
  Checkbox,
  Tooltip,
  InputAdornment,
  CircularProgress,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import {
  TextNumField,
  LedgerAmountField,
  TextField,
  DateTimeField,
} from "@mesh/common-js-react/dist/FormFields";
import { FixedRateBondStub } from "@mesh/common-js/dist/financial/fixedRateBondStub_pb";
import { FutureAmount } from "@mesh/common-js/dist/ledger/amount_pb";
import { newAmountFromBigNumber } from "@mesh/common-js/dist/ledger";
import { FutureToken } from "@mesh/common-js/dist/ledger/token_pb";
import { FutureNetwork } from "@mesh/common-js/dist/ledger/network_pb";
import { Decimal } from "@mesh/common-js/dist/num/decimal_pb";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import * as XLSX from "xlsx";
import {
  allBusinessDayConventions,
  businessDayConventionToString,
  allCouponFrequencies,
  couponFrequencyToString,
  allCalendars,
  calendarToString,
  allDayCountConventions,
  dayCountConventionToString,
  allDateGenerationRules,
  dateGenerationRuleToString,
} from "@mesh/common-js/dist/financial";
import BigNumber from "bignumber.js";
import {
  bigNumberToDecimal,
  decimalToBigNumber,
  formatTextNum,
} from "@mesh/common-js/dist/num";
import { BusinessDayConvention } from "@mesh/common-js/dist/financial/businessDayConvention_pb";
import {
  dayjsToProtobufTimestamp,
  protobufTimestampToDayjs,
} from "@mesh/common-js/dist/googleProtobufConverters";
import dayjs from "dayjs";
import { CouponFrequency } from "@mesh/common-js/dist/financial/couponFrequency_pb";
import { Calendar } from "@mesh/common-js/dist/financial/calendar_pb";
import { DayCountConvention } from "@mesh/common-js/dist/financial/dayCountConvention_pb";
import {
  CalculateFixedRateBondRequest,
  CalculateFixedRateBondResponse,
} from "@mesh/common-js/dist/financial/fixedRateBondCalculator_pb";
import {
  DataTable,
  RowType,
} from "@mesh/common-js-react/dist/Tables/DataTable";
import { CouponPaymentStub } from "@mesh/common-js/dist/financial/couponPaymentStub_pb";
import { Timestamp } from "google-protobuf/google/protobuf/timestamp_pb";
import { DateGenerationRule } from "@mesh/common-js/dist/financial/dateGenerationRule_pb";
import { toast } from "react-toastify";
import { Info as InfoIcon } from "@mui/icons-material";
import { useAPIContext } from "../../../context/API";
import { Timezone } from "@mesh/common-js/dist/i8n/timezone_pb";
import { timezoneToString } from "@mesh/common-js/dist/i8n";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
dayjs.extend(utc);
dayjs.extend(timezone);

const dateFormat = "YYYY/MM/DD HH:mm:ss";

interface FixedRateFormProps {
  timezone: Timezone;
  evaluationTime: Timestamp;
  openForm: boolean;
  exportExcelFunc: React.MutableRefObject<() => void>;
  calculateFunc: React.MutableRefObject<() => void>;
  resetFunc: React.MutableRefObject<() => void>;
}

function getDefaultBond(): FixedRateBondStub {
  return new FixedRateBondStub()
    .setIssuedate(
      dayjsToProtobufTimestamp(dayjs().utc().startOf("day").set("h", -2)),
    )
    .setMaturitydate(
      dayjsToProtobufTimestamp(
        dayjs().utc().startOf("day").set("h", -2).add(10, "y"),
      ),
    )
    .setCalendar(Calendar.SOUTH_AFRICA_CALENDAR)
    .setTotalnominal(
      newAmountFromBigNumber(
        BigNumber(100000000),
        new FutureToken()
          .setCode("mZAR")
          .setIssuer("GCBNWTCCMC32UHZ5OCC2PNMFDGXRVPA7MFFBFFTCVW77SX5PMRB7Q4BY")
          .setNetwork(FutureNetwork.STELLAR_PUBLIC_NETWORK),
      ),
    )
    .setCouponrate(bigNumberToDecimal(BigNumber("5.12")))
    .setFirstcouponpaymentdate(dayjsToProtobufTimestamp(dayjs()))
    .setNexttolastcouponpaymentdate(undefined)
    .setCouponinterestcommencementdate(undefined)
    .setFirstcouponpaymentdate(undefined)
    .setEndofmonthconvention(false)
    .setBusinessdayconvention(
      BusinessDayConvention.FOLLOWING_BUSINESS_DAY_CONVENTION,
    )
    .setTerminationdatebusinessdayconvention(
      BusinessDayConvention.UNDEFINED_BUSINESS_DAY_CONVENTION,
    )
    .setDaycountconvention(
      DayCountConvention.ACTUAL_OVER_365_FIXED_DAY_COUNT_CONVENTION,
    )
    .setCouponpaymentfrequency(CouponFrequency.MONTHLY_COUPON_FREQUENCY)
    .setDategenerationrule(DateGenerationRule.BACKWARD_DATE_GENERATION_RULE);
}

export const FixedRateForm = ({
  openForm,
  exportExcelFunc,
  calculateFunc,
  resetFunc,
  timezone,
  evaluationTime,
}: FixedRateFormProps) => {
  const { financial } = useAPIContext();
  const [formState, setFormState] = useState<{
    fixedRateBondStub: FixedRateBondStub;
  }>({
    fixedRateBondStub: getDefaultBond(),
  });
  const [calculateResponse, setCalculatResponse] = useState<
    CalculateFixedRateBondResponse | undefined
  >(undefined);
  const [loading, setLoading] = useState(false);

  const couponPaymentColumns: RowType<
    CouponPaymentStub,
    CouponPaymentStub.AsObject
  > = useMemo(
    () => ({
      sequencenumber: {
        title: "Seq. No.",
        renderCell: (rowData: CouponPaymentStub) => {
          return rowData.getSequencenumber();
        },
      },
      paymentdate: {
        title: "Payment Date",
        renderCell: (rowData: CouponPaymentStub) => {
          return protobufTimestampToDayjs(
            rowData.getPaymentdate() ?? new Timestamp(),
          )
            .tz(timezoneToString(timezone))
            .format(dateFormat);
        },
      },
      accrualstartdate: {
        title: "Accrual Start Date",
        renderCell: (rowData: CouponPaymentStub) => {
          return protobufTimestampToDayjs(
            rowData.getAccrualstartdate() ?? new Timestamp(),
          )
            .tz(timezoneToString(timezone))
            .format(dateFormat);
        },
      },
      accrualenddate: {
        title: "Accrual End Date",
        renderCell: (rowData: CouponPaymentStub) => {
          return protobufTimestampToDayjs(
            rowData.getAccrualenddate() ?? new Timestamp(),
          )
            .tz(timezoneToString(timezone))
            .format(dateFormat);
        },
      },
      referenceperiodstartdate: {
        title: "Ref. Period Start Date",
        renderCell: (rowData: CouponPaymentStub) => {
          return protobufTimestampToDayjs(
            rowData.getReferenceperiodstartdate() ?? new Timestamp(),
          )
            .tz(timezoneToString(timezone))
            .format(dateFormat);
        },
      },
      referenceperiodenddate: {
        title: "Ref. Period End Date",
        renderCell: (rowData: CouponPaymentStub) => {
          return protobufTimestampToDayjs(
            rowData.getReferenceperiodenddate() ?? new Timestamp(),
          )
            .tz(timezoneToString(timezone))
            .format(dateFormat);
        },
      },
      fixdate: {
        title: "Fix Date",
        renderCell: (rowData: CouponPaymentStub) => {
          return protobufTimestampToDayjs(
            rowData.getFixdate() ?? new Timestamp(),
          )
            .tz(timezoneToString(timezone))
            .format(dateFormat);
        },
      },
      excoupondate: {
        title: "Ex-Coupon Date",
        renderCell: (rowData: CouponPaymentStub) => {
          return protobufTimestampToDayjs(
            rowData.getExcoupondate() ?? new Timestamp(),
          )
            .tz(timezoneToString(timezone))
            .format(dateFormat);
        },
      },
      recorddate: {
        title: "Record Date",
        renderCell: (rowData: CouponPaymentStub) => {
          return protobufTimestampToDayjs(
            rowData.getRecorddate() ?? new Timestamp(),
          )
            .tz(timezoneToString(timezone))
            .format(dateFormat);
        },
      },
      nominalamount: {
        title: "Nominal Amount",
        renderCell: (rowData: CouponPaymentStub) => {
          return `${rowData
            .getNominalamount()
            ?.getToken()
            ?.getCode()} ${decimalToBigNumber(
            rowData.getNominalamount()?.getValue() ?? new Decimal(),
          ).toNumber()}`;
        },
      },
      interestamount: {
        title: "Interest Amount",
        renderCell: (rowData: CouponPaymentStub) => {
          return `${rowData
            .getInterestamount()
            ?.getToken()
            ?.getCode()} ${decimalToBigNumber(
            rowData.getInterestamount()?.getValue() ?? new Decimal(),
          ).toNumber()}`;
        },
      },
      rate: {
        title: "Rate",
        renderCell: (rowData: CouponPaymentStub) => {
          return formatTextNum(
            decimalToBigNumber(rowData.getRate() ?? new Decimal()),
          );
        },
      },
    }),
    [timezone],
  );

  resetFunc.current = () => {
    setFormState({ ...formState, fixedRateBondStub: getDefaultBond() });
    setCalculatResponse(undefined);
  };

  const calculate = useCallback(async () => {
    setLoading(true);
    try {
      setCalculatResponse(
        await financial.fixedRateBondCalculator.calculateFixedRateBond(
          new CalculateFixedRateBondRequest()
            .setFixedratebondstub(formState.fixedRateBondStub)
            .setEvaluationdate(evaluationTime),
        ),
      );
    } catch (e: unknown) {
      toast.error(`Error generating coupon payments: ${e}`, {
        position: "bottom-left",
      });
    }
    setLoading(false);
  }, [formState, evaluationTime]);
  useEffect(() => {
    calculateFunc.current = calculate;
  }, [calculate]);

  const generateExcelFile = useCallback(() => {
    if (!calculateResponse) {
      return;
    }

    const data = calculateResponse.getCouponpaymentsList().map((payment) => {
      return {
        "Sequence Number": payment.getSequencenumber(),
        Date: protobufTimestampToDayjs(
          payment.getPaymentdate() ?? new Timestamp(),
        ).format("DD MMMM YYYY/ HH:mm:ss"),
        [`Principal Amount (${payment
          .getNominalamount()
          ?.getToken()
          ?.getCode()})`]: decimalToBigNumber(
          payment.getNominalamount()?.getValue() ?? new Decimal(),
        ).toNumber(),
        [`Interest Amount (${payment
          .getInterestamount()
          ?.getToken()
          ?.getCode()})`]: decimalToBigNumber(
          payment.getInterestamount()?.getValue() ?? new Decimal(),
        ).toNumber(),
      };
    });

    // Create a new workbook
    const wb = XLSX.utils.book_new();
    // Create a worksheet and add data to it
    const ws = XLSX.utils.json_to_sheet(data);
    const inputSheet = XLSX.utils.json_to_sheet(
      ((): unknown[] => {
        const bond = formState.fixedRateBondStub;
        return Object.entries({
          issuedate: protobufTimestampToDayjs(
            bond.getIssuedate() ?? new Timestamp(),
          ).format("DD MMMM YYYY/ HH:mm:ss"),
          maturitydate: protobufTimestampToDayjs(
            bond.getMaturitydate() ?? new Timestamp(),
          ).format("DD MMMM YYYY/ HH:mm:ss"),
          calendar: calendarToString(bond.getCalendar()),
          totalnominal: decimalToBigNumber(
            bond.getTotalnominal()?.getValue() ?? new Decimal(),
          ).toNumber(),
          couponrate: decimalToBigNumber(
            bond.getCouponrate() ?? new Decimal(),
          ).toNumber(),
          couponpaymentfrequency: couponFrequencyToString(
            bond.getCouponpaymentfrequency(),
          ),
          firstcouponpaymentdate: protobufTimestampToDayjs(
            bond.getFirstcouponpaymentdate() ?? new Timestamp(),
          ).format("DD MMMM YYYY/ HH:mm:ss"),
          nexttolastcouponpaymentdate: protobufTimestampToDayjs(
            bond.getNexttolastcouponpaymentdate() ?? new Timestamp(),
          ).format("DD MMMM YYYY/ HH:mm:ss"),
          couponinterestcommencementdate: protobufTimestampToDayjs(
            bond.getCouponinterestcommencementdate() ?? new Timestamp(),
          ).format("DD MMMM YYYY/ HH:mm:ss"),
          endofmonthconvention: bond.getEndofmonthconvention(),
          businessdayconvention: businessDayConventionToString(
            bond.getBusinessdayconvention(),
          ),
          terminationdatebusinessdayconvention: businessDayConventionToString(
            bond.getTerminationdatebusinessdayconvention(),
          ),
          daycountconvention: dayCountConventionToString(
            bond.getDaycountconvention(),
          ),
          dategenerationrule: dateGenerationRuleToString(
            bond.getDategenerationrule(),
          ),
        }).map(([key, value]) => ({
          Key: key,
          Value: value,
        }));
      })(),
      { skipHeader: true },
    );
    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(wb, inputSheet, "Bond Input");
    XLSX.utils.book_append_sheet(wb, ws, "Coupon payments");
    // Generate a blob from the workbook
    XLSX.writeFile(
      wb,
      `floatingRateBond-${protobufTimestampToDayjs(
        formState.fixedRateBondStub.getIssuedate() ?? new Timestamp(),
      ).format("YYYY-MM-DD")}.xlsx`,
    );
  }, [calculateResponse]);
  useEffect(() => {
    exportExcelFunc.current = generateExcelFile;
  }, [generateExcelFile]);

  return (
    <Box
      sx={(theme) => ({
        display: "flex",
        flexDirection: "column",
        gap: theme.spacing(1),
      })}
    >
      <Collapse in={openForm}>
        <Box
          sx={(theme) => ({
            display: "grid",
            rowGap: theme.spacing(1),
            columnGap: theme.spacing(1),
            alignItems: "center",
            gridTemplateColumns: "repeat(5, 1fr)",
          })}
        >
          <Typography variant="h6" sx={{ gridColumn: "1/6" }}>
            Standard Fields
          </Typography>

          {/* --------------- ROW 1 --------------- */}
          <DateTimeField
            timezone={timezone}
            disabled={loading}
            label="Issue Date"
            value={formState.fixedRateBondStub.getIssuedate()}
            onChange={(newValue) =>
              setFormState({
                ...formState,
                fixedRateBondStub:
                  formState.fixedRateBondStub.setIssuedate(newValue),
              })
            }
          />
          <DateTimeField
            timezone={timezone}
            disabled={loading}
            label="Maturity Date"
            value={formState.fixedRateBondStub.getMaturitydate()}
            onChange={(newValue) =>
              setFormState({
                ...formState,
                fixedRateBondStub:
                  formState.fixedRateBondStub.setMaturitydate(newValue),
              })
            }
          />
          <LedgerAmountField
            noDecimalPlaces={7}
            disabled={loading}
            fullWidth
            label="Total Nominal"
            value={formState.fixedRateBondStub.getTotalnominal()}
            onChange={(newValue: FutureAmount) => {
              setFormState({
                ...formState,
                fixedRateBondStub:
                  formState.fixedRateBondStub.setTotalnominal(newValue),
              });
            }}
          />
          <TextField
            disabled={loading}
            fullWidth
            label="Calendar"
            select
            value={formState.fixedRateBondStub.getCalendar()}
            onChange={(e) =>
              setFormState({
                ...formState,
                fixedRateBondStub: formState.fixedRateBondStub.setCalendar(
                  Number(e.target.value),
                ),
              })
            }
          >
            {allCalendars.map((cal, idx) => (
              <MenuItem key={idx} value={cal}>
                {calendarToString(cal)}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            disabled={loading}
            fullWidth
            label="Coupon Payment Freq."
            select
            value={formState.fixedRateBondStub.getCouponpaymentfrequency()}
            onChange={(e) =>
              setFormState({
                ...formState,
                fixedRateBondStub:
                  formState.fixedRateBondStub.setCouponpaymentfrequency(
                    Number(e.target.value),
                  ),
              })
            }
          >
            {allCouponFrequencies.map((cpf, idx) => (
              <MenuItem key={idx} value={cpf}>
                {couponFrequencyToString(cpf)}
              </MenuItem>
            ))}
          </TextField>

          {/* --------------- ROW 2 --------------- */}
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <TextField
              disabled={loading}
              fullWidth
              label="Bus. Day Convention"
              select
              value={formState.fixedRateBondStub.getBusinessdayconvention()}
              onChange={(e) =>
                setFormState({
                  ...formState,
                  fixedRateBondStub:
                    formState.fixedRateBondStub.setBusinessdayconvention(
                      Number(e.target.value),
                    ),
                })
              }
            >
              {allBusinessDayConventions.map((bdc, idx) => (
                <MenuItem key={idx} value={bdc}>
                  {businessDayConventionToString(bdc)}
                </MenuItem>
              ))}
            </TextField>
            <Tooltip title="business day convention to adjust dates for weekends and holidays">
              <InfoIcon sx={{ ml: 1 }} />
            </Tooltip>
          </Box>
          <TextField
            disabled={loading}
            fullWidth
            label="Date Generation Rule"
            select
            value={formState.fixedRateBondStub.getDategenerationrule()}
            onChange={(e) =>
              setFormState({
                ...formState,
                fixedRateBondStub:
                  formState.fixedRateBondStub.setDategenerationrule(
                    Number(e.target.value),
                  ),
              })
            }
          >
            {allDateGenerationRules.map((v) => {
              return (
                <MenuItem key={v} value={v}>
                  {dateGenerationRuleToString(v)}
                </MenuItem>
              );
            })}
          </TextField>
          <TextField
            disabled={loading}
            fullWidth
            label="Day Count Convention"
            select
            value={formState.fixedRateBondStub.getDaycountconvention()}
            onChange={(e) =>
              setFormState({
                ...formState,
                fixedRateBondStub:
                  formState.fixedRateBondStub.setDaycountconvention(
                    Number(e.target.value),
                  ),
              })
            }
          >
            {allDayCountConventions.map((dcc, idx) => (
              <MenuItem key={idx} value={dcc}>
                {dayCountConventionToString(dcc)}
              </MenuItem>
            ))}
          </TextField>
          <Box
            sx={{
              gridColumn: "4/6",
              display: "grid",
              width: "100%",
              gridTemplateColumns: "1fr 1fr 1fr",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <TextField
                type="number"
                value={formState.fixedRateBondStub.getFixdays()}
                onChange={(e) =>
                  setFormState({
                    ...formState,
                    fixedRateBondStub: formState.fixedRateBondStub.setFixdays(
                      Number(e.target.value),
                    ),
                  })
                }
                label="Fix Days"
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  min: 0,
                }}
                variant="outlined"
                margin="normal"
              />
              <Tooltip
                title={`FixDays is the number of days before a coupon payment date that the amount of the coupon payment is determined.
            It thus determines the "FixDate" as CouponPayment.PaymentDate - FixDays.
            From the fix day onward the issuer knows with 100% certainty how much they will be liable for on coupon payment date.`}
              >
                <InfoIcon sx={{ ml: 1 }} />
              </Tooltip>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <TextField
                type="number"
                value={formState.fixedRateBondStub.getExcoupondays()}
                onChange={(e) =>
                  setFormState({
                    ...formState,
                    fixedRateBondStub:
                      formState.fixedRateBondStub.setExcoupondays(
                        Number(e.target.value),
                      ),
                  })
                }
                label="ExCoupon Days"
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  min: 0,
                }}
                variant="outlined"
                margin="normal"
              />
              <Tooltip
                title={`ExCouponDays is the number of days before a coupon payment date during which a bond may be sold without the right to receive the next coupon payment.
            It thus determines “ExCouponDate” as CouponPayment.PaymentDate - ExCouponDays.
            From the ExCouponDate onward the seller is likely to retain the right to receive the coupon payment based on predicted trade settlement.
            ExcouonDays must be greater than or equal to RecordDays.
            Note that ExCouponDate is essentially a convenience set by the issuer, based on the underlying market technology, that indicates the last day on which, if you buy the bond,
            your trade is likely to settle before RecordDate (determined by RecordDays). In the market it is typically the day from which a bond will trade at a price that does not reflect
            the upcoming coupon payment priced into it. This is essentially irrelevant for a purely blockchain traded bond as absolute ownership can be determined at any point in time by
            looking at the history of the chain. For a purely blockchain traded instrument exCouponDays may be equal to recordDays.`}
              >
                <InfoIcon sx={{ ml: 1 }} />
              </Tooltip>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <TextField
                type="number"
                value={formState.fixedRateBondStub.getRecorddays()}
                onChange={(e) =>
                  setFormState({
                    ...formState,
                    fixedRateBondStub:
                      formState.fixedRateBondStub.setRecorddays(
                        Number(e.target.value),
                      ),
                  })
                }
                label="Record Days"
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  min: 0,
                }}
                variant="outlined"
                margin="normal"
              />
              <Tooltip
                title={`RecordDays is the number of days before coupon payment date that the bond issuer checks their records to determine who the current bondholders are and,
            therefore, who is entitled to receive the coupon payment. It thus determines “RecordDate” as CouponPaymentDate - FixDays. RecordDate defines the absolute cutoff for
            determining eligibility for coupon payments. Whoever holds the point at this point gets the coupon payment.`}
              >
                <InfoIcon sx={{ ml: 1 }} />
              </Tooltip>
            </Box>
          </Box>

          <Typography variant="h6" sx={{ gridColumn: "1/6" }}>
            Special Fields
          </Typography>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <DateTimeField
              timezone={timezone}
              disabled={loading}
              label="First Coupon Payment Date"
              nullable
              value={formState.fixedRateBondStub.getFirstcouponpaymentdate()}
              onChange={(newValue) =>
                setFormState({
                  ...formState,
                  fixedRateBondStub:
                    formState.fixedRateBondStub.setFirstcouponpaymentdate(
                      newValue,
                    ),
                })
              }
            />
            <Tooltip title="Optional Field. Represents the first date in the schedule. Can be used to defer first coupon payment date.">
              <InfoIcon sx={{ ml: 1 }} />
            </Tooltip>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <DateTimeField
              timezone={timezone}
              disabled={loading}
              label="Coupon Interest Start Date"
              nullable
              value={formState.fixedRateBondStub.getCouponinterestcommencementdate()}
              onChange={(newValue) =>
                setFormState({
                  ...formState,
                  fixedRateBondStub:
                    formState.fixedRateBondStub.setCouponinterestcommencementdate(
                      newValue,
                    ),
                })
              }
            />
            <Tooltip title="Optional Field. This is the effective/start date of the payment schedule. If left blank the issue date is used as the start date on the schedule">
              <InfoIcon sx={{ ml: 1 }} />
            </Tooltip>
          </Box>

          <Box sx={{ display: "flex", alignItems: "center" }}>
            <DateTimeField
              timezone={timezone}
              disabled={loading}
              nullable
              label="Next To Last Coupon Payment Date"
              value={formState.fixedRateBondStub.getNexttolastcouponpaymentdate()}
              onChange={(newValue) =>
                setFormState({
                  ...formState,
                  fixedRateBondStub:
                    formState.fixedRateBondStub.setNexttolastcouponpaymentdate(
                      newValue,
                    ),
                })
              }
            />
            <Tooltip title="Optional Field. This can be used to manually set the second to last coupon payment date">
              <InfoIcon sx={{ ml: 1 }} />
            </Tooltip>
          </Box>

          <Box sx={{ display: "flex", alignItems: "center" }}>
            <TextField
              disabled={loading}
              fullWidth
              label="Termination Date Bus. Day Convention"
              select
              value={formState.fixedRateBondStub.getTerminationdatebusinessdayconvention()}
              onChange={(e) =>
                setFormState({
                  ...formState,
                  fixedRateBondStub:
                    formState.fixedRateBondStub.setTerminationdatebusinessdayconvention(
                      Number(e.target.value),
                    ),
                })
              }
            >
              {allBusinessDayConventions.map((bdc, idx) => (
                <MenuItem key={idx} value={bdc}>
                  {businessDayConventionToString(bdc)}
                </MenuItem>
              ))}
            </TextField>
            <Tooltip title="optional field. This can be used to provide a different business day convention for the last date in the payment schedule.">
              <InfoIcon sx={{ ml: 1 }} />
            </Tooltip>
          </Box>
          <Box
            className="checkboxes"
            sx={{ display: "flex", gap: 2, width: "100%", ml: 1 }}
          >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <FormControlLabel
                disabled={loading}
                control={<Checkbox sx={{ mr: 1 }} />}
                label="End of Month Conv."
                checked={formState.fixedRateBondStub.getEndofmonthconvention()}
                onChange={(_, checked) =>
                  setFormState({
                    ...formState,
                    fixedRateBondStub:
                      formState.fixedRateBondStub.setEndofmonthconvention(
                        checked,
                      ),
                  })
                }
              />
              <Tooltip title="indicates whether dates should be adjusted to the end of the month if they don't fall on a business day">
                <InfoIcon sx={{ ml: 1 }} />
              </Tooltip>
            </Box>
          </Box>

          <Typography variant="h6" sx={{ gridColumn: "1/6" }}>
            Rate Fields
          </Typography>
          <TextNumField
            disabled={loading}
            fullWidth
            label="Coupon Rate"
            noDecimalPlaces={7}
            value={formState.fixedRateBondStub.getCouponrate()}
            onChange={(newValue: Decimal) =>
              setFormState({
                ...formState,
                fixedRateBondStub:
                  formState.fixedRateBondStub.setCouponrate(newValue),
              })
            }
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
            }}
          />
        </Box>
      </Collapse>

      <Typography variant="h6" sx={{ gridColumn: "1/6" }}>
        Calulation Results (as at evaluation date)
      </Typography>

      {(() => {
        if (loading) {
          return <CircularProgress />;
        }

        if (calculateResponse) {
          return (
            <>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography sx={{ width: "33%", flexShrink: 0 }}>
                    Pricing
                  </Typography>
                  <Typography sx={{ color: "text.secondary" }}>
                    Clean and Dirty Price as at evaluation date
                  </Typography>
                </AccordionSummary>
                <AccordionDetails
                  sx={(theme) => ({
                    display: "flex",
                    flexDirection: "row",
                    gap: theme.spacing(1),
                  })}
                >
                  <Box>
                    <Typography variant="overline" color={"textSecondary"}>
                      Clean Price
                    </Typography>
                    <Typography>
                      {`${calculateResponse
                        .getCleanprice()
                        ?.getToken()
                        ?.getCode()} ${decimalToBigNumber(
                        calculateResponse.getCleanprice()?.getValue() ??
                          new Decimal(),
                      ).toNumber()}`}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography variant="overline" color={"textSecondary"}>
                      Dirty Price
                    </Typography>
                    <Typography>
                      {`${calculateResponse
                        .getDirtyprice()
                        ?.getToken()
                        ?.getCode()} ${decimalToBigNumber(
                        calculateResponse.getDirtyprice()?.getValue() ??
                          new Decimal(),
                      ).toNumber()}`}
                    </Typography>
                  </Box>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography sx={{ width: "33%", flexShrink: 0 }}>
                    Maturity
                  </Typography>
                  <Typography sx={{ color: "text.secondary" }}>
                    Maturity Payment Breakdown
                  </Typography>
                </AccordionSummary>
                <AccordionDetails
                  sx={(theme) => ({
                    display: "flex",
                    flexDirection: "row",
                    gap: theme.spacing(1),
                  })}
                >
                  <Box>
                    <Typography variant="overline" color={"textSecondary"}>
                      Payment Date
                    </Typography>
                    <Typography>
                      {protobufTimestampToDayjs(
                        calculateResponse
                          .getMaturitypayment()
                          ?.getPaymentdate() ?? new Timestamp(),
                      )
                        .tz(timezoneToString(timezone))
                        .format()}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography variant="overline" color={"textSecondary"}>
                      Fix Date
                    </Typography>
                    <Typography>
                      {protobufTimestampToDayjs(
                        calculateResponse.getMaturitypayment()?.getFixdate() ??
                          new Timestamp(),
                      )
                        .tz(timezoneToString(timezone))
                        .format()}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography variant="overline" color={"textSecondary"}>
                      Excoupon Date
                    </Typography>
                    <Typography>
                      {protobufTimestampToDayjs(
                        calculateResponse
                          .getMaturitypayment()
                          ?.getExcoupondate() ?? new Timestamp(),
                      )
                        .tz(timezoneToString(timezone))
                        .format()}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography variant="overline" color={"textSecondary"}>
                      Record Date
                    </Typography>
                    <Typography>
                      {protobufTimestampToDayjs(
                        calculateResponse
                          .getMaturitypayment()
                          ?.getRecorddate() ?? new Timestamp(),
                      )
                        .tz(timezoneToString(timezone))
                        .format()}
                    </Typography>
                  </Box>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography sx={{ width: "33%", flexShrink: 0 }}>
                    Coupon Payments
                  </Typography>
                  <Typography sx={{ color: "text.secondary" }}>
                    Coupon payments over the entire lifecycle of the bond
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <DataTable
                    height={400}
                    data={calculateResponse.getCouponpaymentsList()}
                    columns={couponPaymentColumns}
                  />
                </AccordionDetails>
              </Accordion>
            </>
          );
        }

        return (
          <Typography>
            Nothing to show - click calculate to show results
          </Typography>
        );
      })()}
    </Box>
  );
};
