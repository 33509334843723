// source: financial/primeRateRecorder.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var financial_primeRate_pb = require('../financial/primeRate_pb.js');
goog.object.extend(proto, financial_primeRate_pb);
var financial_primeRateStub_pb = require('../financial/primeRateStub_pb.js');
goog.object.extend(proto, financial_primeRateStub_pb);
var num_decimal_pb = require('../num/decimal_pb.js');
goog.object.extend(proto, num_decimal_pb);
goog.exportSymbol('proto.financial.CapturePrimeRateRequest', null, global);
goog.exportSymbol('proto.financial.CapturePrimeRateResponse', null, global);
goog.exportSymbol('proto.financial.ReplacePrimeRatesRequest', null, global);
goog.exportSymbol('proto.financial.ReplacePrimeRatesResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.financial.CapturePrimeRateRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.financial.CapturePrimeRateRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.financial.CapturePrimeRateRequest.displayName = 'proto.financial.CapturePrimeRateRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.financial.CapturePrimeRateResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.financial.CapturePrimeRateResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.financial.CapturePrimeRateResponse.displayName = 'proto.financial.CapturePrimeRateResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.financial.ReplacePrimeRatesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.financial.ReplacePrimeRatesRequest.repeatedFields_, null);
};
goog.inherits(proto.financial.ReplacePrimeRatesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.financial.ReplacePrimeRatesRequest.displayName = 'proto.financial.ReplacePrimeRatesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.financial.ReplacePrimeRatesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.financial.ReplacePrimeRatesResponse.repeatedFields_, null);
};
goog.inherits(proto.financial.ReplacePrimeRatesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.financial.ReplacePrimeRatesResponse.displayName = 'proto.financial.ReplacePrimeRatesResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.financial.CapturePrimeRateRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.financial.CapturePrimeRateRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.financial.CapturePrimeRateRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.financial.CapturePrimeRateRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    date: (f = msg.getDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    rate: (f = msg.getRate()) && num_decimal_pb.Decimal.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.financial.CapturePrimeRateRequest}
 */
proto.financial.CapturePrimeRateRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.financial.CapturePrimeRateRequest;
  return proto.financial.CapturePrimeRateRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.financial.CapturePrimeRateRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.financial.CapturePrimeRateRequest}
 */
proto.financial.CapturePrimeRateRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setDate(value);
      break;
    case 2:
      var value = new num_decimal_pb.Decimal;
      reader.readMessage(value,num_decimal_pb.Decimal.deserializeBinaryFromReader);
      msg.setRate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.financial.CapturePrimeRateRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.financial.CapturePrimeRateRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.financial.CapturePrimeRateRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.financial.CapturePrimeRateRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getRate();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      num_decimal_pb.Decimal.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Timestamp date = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.financial.CapturePrimeRateRequest.prototype.getDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.financial.CapturePrimeRateRequest} returns this
*/
proto.financial.CapturePrimeRateRequest.prototype.setDate = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.financial.CapturePrimeRateRequest} returns this
 */
proto.financial.CapturePrimeRateRequest.prototype.clearDate = function() {
  return this.setDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.financial.CapturePrimeRateRequest.prototype.hasDate = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional num.Decimal rate = 2;
 * @return {?proto.num.Decimal}
 */
proto.financial.CapturePrimeRateRequest.prototype.getRate = function() {
  return /** @type{?proto.num.Decimal} */ (
    jspb.Message.getWrapperField(this, num_decimal_pb.Decimal, 2));
};


/**
 * @param {?proto.num.Decimal|undefined} value
 * @return {!proto.financial.CapturePrimeRateRequest} returns this
*/
proto.financial.CapturePrimeRateRequest.prototype.setRate = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.financial.CapturePrimeRateRequest} returns this
 */
proto.financial.CapturePrimeRateRequest.prototype.clearRate = function() {
  return this.setRate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.financial.CapturePrimeRateRequest.prototype.hasRate = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.financial.CapturePrimeRateResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.financial.CapturePrimeRateResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.financial.CapturePrimeRateResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.financial.CapturePrimeRateResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    primerate: (f = msg.getPrimerate()) && financial_primeRate_pb.PrimeRate.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.financial.CapturePrimeRateResponse}
 */
proto.financial.CapturePrimeRateResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.financial.CapturePrimeRateResponse;
  return proto.financial.CapturePrimeRateResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.financial.CapturePrimeRateResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.financial.CapturePrimeRateResponse}
 */
proto.financial.CapturePrimeRateResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new financial_primeRate_pb.PrimeRate;
      reader.readMessage(value,financial_primeRate_pb.PrimeRate.deserializeBinaryFromReader);
      msg.setPrimerate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.financial.CapturePrimeRateResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.financial.CapturePrimeRateResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.financial.CapturePrimeRateResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.financial.CapturePrimeRateResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPrimerate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      financial_primeRate_pb.PrimeRate.serializeBinaryToWriter
    );
  }
};


/**
 * optional PrimeRate primeRate = 1;
 * @return {?proto.financial.PrimeRate}
 */
proto.financial.CapturePrimeRateResponse.prototype.getPrimerate = function() {
  return /** @type{?proto.financial.PrimeRate} */ (
    jspb.Message.getWrapperField(this, financial_primeRate_pb.PrimeRate, 1));
};


/**
 * @param {?proto.financial.PrimeRate|undefined} value
 * @return {!proto.financial.CapturePrimeRateResponse} returns this
*/
proto.financial.CapturePrimeRateResponse.prototype.setPrimerate = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.financial.CapturePrimeRateResponse} returns this
 */
proto.financial.CapturePrimeRateResponse.prototype.clearPrimerate = function() {
  return this.setPrimerate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.financial.CapturePrimeRateResponse.prototype.hasPrimerate = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.financial.ReplacePrimeRatesRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.financial.ReplacePrimeRatesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.financial.ReplacePrimeRatesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.financial.ReplacePrimeRatesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.financial.ReplacePrimeRatesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    primeratestubsList: jspb.Message.toObjectList(msg.getPrimeratestubsList(),
    financial_primeRateStub_pb.PrimeRateStub.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.financial.ReplacePrimeRatesRequest}
 */
proto.financial.ReplacePrimeRatesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.financial.ReplacePrimeRatesRequest;
  return proto.financial.ReplacePrimeRatesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.financial.ReplacePrimeRatesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.financial.ReplacePrimeRatesRequest}
 */
proto.financial.ReplacePrimeRatesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new financial_primeRateStub_pb.PrimeRateStub;
      reader.readMessage(value,financial_primeRateStub_pb.PrimeRateStub.deserializeBinaryFromReader);
      msg.addPrimeratestubs(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.financial.ReplacePrimeRatesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.financial.ReplacePrimeRatesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.financial.ReplacePrimeRatesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.financial.ReplacePrimeRatesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPrimeratestubsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      financial_primeRateStub_pb.PrimeRateStub.serializeBinaryToWriter
    );
  }
};


/**
 * repeated PrimeRateStub primeRateStubs = 1;
 * @return {!Array<!proto.financial.PrimeRateStub>}
 */
proto.financial.ReplacePrimeRatesRequest.prototype.getPrimeratestubsList = function() {
  return /** @type{!Array<!proto.financial.PrimeRateStub>} */ (
    jspb.Message.getRepeatedWrapperField(this, financial_primeRateStub_pb.PrimeRateStub, 1));
};


/**
 * @param {!Array<!proto.financial.PrimeRateStub>} value
 * @return {!proto.financial.ReplacePrimeRatesRequest} returns this
*/
proto.financial.ReplacePrimeRatesRequest.prototype.setPrimeratestubsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.financial.PrimeRateStub=} opt_value
 * @param {number=} opt_index
 * @return {!proto.financial.PrimeRateStub}
 */
proto.financial.ReplacePrimeRatesRequest.prototype.addPrimeratestubs = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.financial.PrimeRateStub, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.financial.ReplacePrimeRatesRequest} returns this
 */
proto.financial.ReplacePrimeRatesRequest.prototype.clearPrimeratestubsList = function() {
  return this.setPrimeratestubsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.financial.ReplacePrimeRatesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.financial.ReplacePrimeRatesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.financial.ReplacePrimeRatesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.financial.ReplacePrimeRatesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.financial.ReplacePrimeRatesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    primeratesList: jspb.Message.toObjectList(msg.getPrimeratesList(),
    financial_primeRate_pb.PrimeRate.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.financial.ReplacePrimeRatesResponse}
 */
proto.financial.ReplacePrimeRatesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.financial.ReplacePrimeRatesResponse;
  return proto.financial.ReplacePrimeRatesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.financial.ReplacePrimeRatesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.financial.ReplacePrimeRatesResponse}
 */
proto.financial.ReplacePrimeRatesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new financial_primeRate_pb.PrimeRate;
      reader.readMessage(value,financial_primeRate_pb.PrimeRate.deserializeBinaryFromReader);
      msg.addPrimerates(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.financial.ReplacePrimeRatesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.financial.ReplacePrimeRatesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.financial.ReplacePrimeRatesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.financial.ReplacePrimeRatesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPrimeratesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      financial_primeRate_pb.PrimeRate.serializeBinaryToWriter
    );
  }
};


/**
 * repeated PrimeRate primeRates = 1;
 * @return {!Array<!proto.financial.PrimeRate>}
 */
proto.financial.ReplacePrimeRatesResponse.prototype.getPrimeratesList = function() {
  return /** @type{!Array<!proto.financial.PrimeRate>} */ (
    jspb.Message.getRepeatedWrapperField(this, financial_primeRate_pb.PrimeRate, 1));
};


/**
 * @param {!Array<!proto.financial.PrimeRate>} value
 * @return {!proto.financial.ReplacePrimeRatesResponse} returns this
*/
proto.financial.ReplacePrimeRatesResponse.prototype.setPrimeratesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.financial.PrimeRate=} opt_value
 * @param {number=} opt_index
 * @return {!proto.financial.PrimeRate}
 */
proto.financial.ReplacePrimeRatesResponse.prototype.addPrimerates = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.financial.PrimeRate, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.financial.ReplacePrimeRatesResponse} returns this
 */
proto.financial.ReplacePrimeRatesResponse.prototype.clearPrimeratesList = function() {
  return this.setPrimeratesList([]);
};


goog.object.extend(exports, proto.financial);
