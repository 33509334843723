import React, { useMemo, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  CircularProgress,
  Divider,
  FormControlLabel,
  IconButton,
  MenuItem,
  Tooltip,
  Typography,
} from "@mui/material";
import { Timestamp } from "google-protobuf/google/protobuf/timestamp_pb";
import {
  DateTimeField,
  TextField,
} from "@mesh/common-js-react/dist/FormFields";
import DownloadIcon from "@mui/icons-material/Download";
import { allCalendars, calendarToString } from "@mesh/common-js/dist/financial";
import { GetHolidaysRequest } from "@mesh/common-js/dist/financial/calendarInspector_pb";
import { Calendar } from "@mesh/common-js/dist/financial/calendar_pb";
import {
  dayjsToProtobufTimestamp,
  protobufTimestampToDayjs,
} from "@mesh/common-js/dist/googleProtobufConverters";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { Timezone } from "@mesh/common-js/dist/i8n/timezone_pb";
import { allTimezones, timezoneToString } from "@mesh/common-js/dist/i8n";
import { Info as InfoIcon } from "@mui/icons-material";
import { toast } from "react-toastify";
import { useAPIContext } from "../../context/API";
import {
  DataTable,
  RowType,
} from "@mesh/common-js-react/dist/Tables/DataTable";
dayjs.extend(utc);
import * as XLSX from "xlsx";

const dateFormat = "YYYY/MM/DD HH:mm:ss";

export const HolidayCalendar = () => {
  const [timezone, setTimezone] = useState<Timezone>(Timezone.SAST_TIMEZONE);
  const [formState, setFormState] = useState<{ request: GetHolidaysRequest }>({
    request: new GetHolidaysRequest()
      .setCalendar(Calendar.SOUTH_AFRICA_CALENDAR)
      .setFromdate(
        dayjsToProtobufTimestamp(
          dayjs().utc().startOf("day").set("h", -2).add(-20, "years"),
        ),
      )
      .setTodate(
        dayjsToProtobufTimestamp(
          dayjs().utc().startOf("day").set("h", -2).add(20, "years"),
        ),
      )
      .setIncludeweekends(true),
  });
  const { financial } = useAPIContext();

  const [holidays, setHolidays] = useState<Timestamp[]>([]);
  const [loading, setLoading] = useState(false);
  const getHolidays = async () => {
    setLoading(true);
    try {
      setHolidays(
        (
          await financial.calendarInspector.getHolidays(formState.request)
        ).getHolidaysList(),
      );
    } catch (e) {
      toast.error(`Error Getting Holidays: ${e}`, {
        position: "bottom-left",
      });
      setLoading(false);
      return;
    }
    toast.success("Holidays Fetched", {
      position: "bottom-left",
    });
    setLoading(false);
  };

  const columns: RowType<Timestamp, { date: Timestamp }> = useMemo(
    () => ({
      date: {
        title: "Date",
        renderCell: (rowData: Timestamp) => {
          return protobufTimestampToDayjs(rowData)
            .tz(timezoneToString(timezone))
            .format(dateFormat);
        },
      },
    }),
    [timezone],
  );

  return (
    <Box sx={{ display: "flex", justifyContent: "center", p: 4 }}>
      <Card sx={{ width: "100%" }}>
        <CardContent sx={{ p: 3 }}>
          <Typography variant="h4" textAlign={"start"}>
            Generate Holiday List
          </Typography>
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "repeat(6, auto)",
              gap: 2,
              my: 2,
            }}
          >
            <TextField
              label="Timezone"
              select
              value={timezone}
              disabled={loading}
              onChange={(e) => setTimezone(Number(e.target.value))}
            >
              {allTimezones
                .filter((tz) => tz != Timezone.UNDEFINED_TIMEZONE)
                .map((tz, idx) => (
                  <MenuItem key={idx} value={tz}>
                    {timezoneToString(tz)}
                  </MenuItem>
                ))}
            </TextField>
            <TextField
              disabled={loading}
              fullWidth
              label="Calendar"
              select
              value={formState.request.getCalendar()}
              onChange={(e) => {
                setHolidays([]);
                setFormState({
                  ...formState,
                  request: formState.request.setCalendar(
                    Number(e.target.value),
                  ),
                });
              }}
            >
              {allCalendars.map((cal, idx) => (
                <MenuItem key={idx} value={cal}>
                  {calendarToString(cal)}
                </MenuItem>
              ))}
            </TextField>
            <DateTimeField
              timezone={timezone}
              disabled={loading}
              label="From"
              value={formState.request.getFromdate()}
              onChange={(newValue) => {
                setHolidays([]);
                setFormState({
                  request: formState.request.setFromdate(newValue),
                });
              }}
            />
            <DateTimeField
              timezone={timezone}
              disabled={loading}
              label="To"
              value={formState.request.getTodate()}
              onChange={(newValue) => {
                setHolidays([]);
                setFormState({
                  request: formState.request.setTodate(newValue),
                });
              }}
            />
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <FormControlLabel
                disabled={loading}
                control={<Checkbox sx={{ mr: 1 }} />}
                label="Include Weekends"
                checked={formState.request.getIncludeweekends()}
                onChange={(_, checked) => {
                  setHolidays([]);
                  setFormState({
                    ...formState,
                    request: formState.request.setIncludeweekends(checked),
                  });
                }}
              />
              <Tooltip title="whether weekends should be included as 'holidays' - i.e. non-business days">
                <InfoIcon sx={{ ml: 1 }} />
              </Tooltip>
            </Box>
            <Button
              variant="contained"
              color="primary"
              disabled={loading}
              onClick={getHolidays}
            >
              Get Holidays
            </Button>
          </Box>
          <Divider />

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              py: 3,
            }}
          >
            <Typography variant="h6" fontWeight={"bold"}>
              Holidays
            </Typography>
            {(() => {
              if (loading) {
                return <CircularProgress />;
              }

              return holidays.length ? (
                <Box>
                  <IconButton
                    onClick={() => {
                      // Create a new workbook
                      const wb = XLSX.utils.book_new();

                      // Create a worksheet and add data to it
                      const ws = XLSX.utils.json_to_sheet(
                        holidays.map((h) => ({
                          Date: protobufTimestampToDayjs(h)
                            .tz(timezoneToString(timezone))
                            .toDate(),
                        })),
                        { cellDates: true, dateNF: "dd-mmm-yyyy" },
                      );

                      // Add the worksheet to the workbook
                      XLSX.utils.book_append_sheet(wb, ws, "Holidays");

                      // Generate a blob from the workbook
                      XLSX.writeFile(wb, "holidays.xlsx");
                    }}
                  >
                    <DownloadIcon />
                  </IconButton>
                  <DataTable height={500} data={holidays} columns={columns} />
                </Box>
              ) : (
                <Typography>Nothing to show - click "Get Holidays"</Typography>
              );
            })()}
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};
