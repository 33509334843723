import React, { useState } from "react";
import {
  Avatar,
  Box,
  Menu,
  MenuItem,
  Typography,
  Tabs,
  Tab,
} from "@mui/material";
import { Outlet, useNavigate } from "react-router-dom";
import meshLogo from "../../assets/images/logo/meshLogo.svg";
import { useFirebaseContext } from "../../context/Firebase";

export type MainAppBarLayoutProps = {
  paths: { name: string; path: string }[];
};

export const MainAppBarLayout = (props: MainAppBarLayoutProps) => {
  const navigate = useNavigate();
  const { firebaseUser, signOut } = useFirebaseContext();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleAvatarClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const activeTabIndex = props.paths.findIndex((path) => {
    return window.location.pathname.includes(path.path);
  });

  return (
    <Box
      sx={(theme) => ({
        width: "100vw",
        height: "100vh",
        backgroundColor: theme.palette.background.default,
      })}
    >
      <Box
        sx={(theme) => ({
          display: "grid",
          alignItems: "center",
          columnGap: theme.spacing(1),
          gridTemplateColumns: "auto auto 1fr",
          height: 55,
          backgroundColor: theme.palette.custom.midnight,
          px: 2,
        })}
      >
        <img style={{ height: 58 }} src={meshLogo} alt="" />

        <Box>
          <Tabs
            value={activeTabIndex}
            onChange={(_, value) => navigate(props.paths[value].path)}
          >
            {props.paths.map((path, idx) => (
              <Tab key={idx} label={path.name} value={idx} />
            ))}
          </Tabs>
        </Box>

        <Box sx={{ ml: "auto", mr: 2, display: "flex" }}>
          <Box
            sx={(theme) => ({
              display: "flex",
              justifyContent: "flex-end",
              borderLeft: `2px solid ${theme.palette.divider}`,
              pl: 3,
              ml: 3,
            })}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                textAlign: "end",
              }}
            >
              <Typography
                variant="body2"
                sx={(theme) => ({ color: theme.palette.text.disabled })}
              >
                Hello
              </Typography>
              <Typography
                sx={(theme) => ({ color: theme.palette.text.primary })}
              >
                {firebaseUser?.displayName}
              </Typography>
            </Box>
            <Avatar
              src={firebaseUser?.photoURL ?? ""}
              alt="User Profile"
              sx={{ ml: 2 }}
              onClick={handleAvatarClick}
              style={{ cursor: "pointer" }}
            />

            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={signOut}>Logout</MenuItem>
            </Menu>
          </Box>
        </Box>
      </Box>
      <Outlet />
    </Box>
  );
};
