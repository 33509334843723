import React, { useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  MenuItem,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { toast } from "react-toastify";
import { useAPIContext } from "../../context/API";
import { CapturePrimeRateRequest } from "@mesh/common-js/dist/financial/primeRateRecorder_pb";
import { dayjsToProtobufTimestamp } from "@mesh/common-js/dist/googleProtobufConverters";
import { bigNumberToDecimal } from "@mesh/common-js/dist/num";
import BigNumber from "bignumber.js";
import {
  DateTimeField,
  TextNumField,
} from "@mesh/common-js-react/dist/FormFields";
import { Decimal } from "@mesh/common-js/dist/num/decimal_pb";
import { SearchPrimeRates } from "./components/SearchPrimeRates";
import { Timezone } from "@mesh/common-js/dist/i8n/timezone_pb";
import { allTimezones, timezoneToString } from "@mesh/common-js/dist/i8n";
import { Info as InfoIcon } from "@mui/icons-material";
import { ReplacePrimeRateDialog } from "./components";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
dayjs.extend(utc);

export const PrimeRateCapturer = () => {
  const [timezone, setTimezone] = useState<Timezone>(Timezone.SAST_TIMEZONE);
  const [formState, setFormState] = useState({
    captureRequest: new CapturePrimeRateRequest()
      .setDate(
        dayjsToProtobufTimestamp(dayjs().utc().startOf("day").set("h", -2)),
      )
      .setRate(bigNumberToDecimal(new BigNumber(10.12))),
  });
  const { financial } = useAPIContext();
  const [loading, setLoading] = useState(false);

  const captureRate = async () => {
    setLoading(true);
    try {
      await financial.primeRateRecorder.capturePrimeRate(
        formState.captureRequest,
      );
    } catch (e) {
      toast.error(`Error Capturing Prime Rate: ${e}`, {
        position: "bottom-left",
      });
      setLoading(false);
      return;
    }
    toast.success("prime rate captured", {
      position: "bottom-left",
    });
    setLoading(false);
  };

  const [replacePrimeRatesDialogOpen, setReplacePrimeRatesDialogOpen] =
    useState(false);

  return (
    <Box sx={{ display: "flex", justifyContent: "center", p: 4 }}>
      <Card sx={{ width: "100%" }}>
        <CardContent sx={{ p: 3 }}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <TextField
              label="Timezone"
              select
              value={timezone}
              onChange={(e) => setTimezone(Number(e.target.value))}
            >
              {allTimezones
                .filter((tz) => tz != Timezone.UNDEFINED_TIMEZONE)
                .map((tz, idx) => (
                  <MenuItem key={idx} value={tz}>
                    {timezoneToString(tz)}
                  </MenuItem>
                ))}
            </TextField>
            <Tooltip title="The timezone in which times will be shown on the form. All timezones stored in UTC.">
              <InfoIcon sx={{ ml: 1 }} />
            </Tooltip>
          </Box>
        </CardContent>
        <CardContent
          sx={(theme) => ({
            display: "flex",
            flexDirection: "column",
            gap: theme.spacing(1),
            textAlign: "start",
          })}
        >
          <Typography variant="h6" sx={{ gridColumn: "1/6" }}>
            Rate Capturing
          </Typography>
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr 2fr",
              gap: 3,
              alignItems: "center",
            }}
          >
            <DateTimeField
              timezone={timezone}
              disabled={loading}
              label="Date"
              nullable
              value={formState.captureRequest.getDate()}
              onChange={(newValue) =>
                setFormState({
                  captureRequest: formState.captureRequest.setDate(newValue),
                })
              }
            />
            <TextNumField
              disabled={loading}
              fullWidth
              label="Coupon Rate"
              value={formState.captureRequest.getRate()}
              onChange={(newValue: Decimal) =>
                setFormState({
                  captureRequest: formState.captureRequest.setRate(newValue),
                })
              }
            />
            <Box sx={{ display: "flex" }}>
              <Button color="primary" variant="contained" onClick={captureRate}>
                Capture Rate
              </Button>
              <Button
                color="primary"
                variant="contained"
                onClick={() => setReplacePrimeRatesDialogOpen(true)}
              >
                Replace Rates
              </Button>
            </Box>
          </Box>
        </CardContent>
        <CardContent
          sx={(theme) => ({
            display: "flex",
            flexDirection: "column",
            gap: theme.spacing(1),
            textAlign: "start",
          })}
        >
          <Typography variant="h6" sx={{ gridColumn: "1/6" }}>
            Search Existing Prime Rates
          </Typography>
          <SearchPrimeRates timezone={timezone} />
        </CardContent>
      </Card>

      {replacePrimeRatesDialogOpen && (
        <ReplacePrimeRateDialog
          closeDialog={() => setReplacePrimeRatesDialogOpen(false)}
          timezone={timezone}
        />
      )}
    </Box>
  );
};
