import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useConfigContext } from "../Config";
import { Environment } from "@mesh/common-js/dist/environment/environment_pb";
import { initializeApp, FirebaseOptions } from "firebase/app";
import {
  getAuth,
  GoogleAuthProvider,
  User,
  signInWithRedirect,
  signOut as firebaseSignOut,
} from "firebase/auth";

export type FirebaseContextType = {
  firebaseUser: User | null;
  firebaseAccessToken: string;
  firebaseLoading: boolean;
  signInWithGoogle: () => void;
  signOut: () => void;
};

const defaultContext: FirebaseContextType = {
  firebaseUser: null,
  firebaseAccessToken: "",
  firebaseLoading: true,
  signInWithGoogle: () => undefined,
  signOut: () => undefined,
};

export const FirebaseContext =
  React.createContext<FirebaseContextType>(defaultContext);

export const FirebaseProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  // initialise firebase
  const { environment } = useConfigContext();
  const config: FirebaseOptions = useMemo(() => {
    // return the firebase options for the active environment
    let firebaseOptions: FirebaseOptions;
    switch (environment) {
      case Environment.LOCAL_ENVIRONMENT:
      case Environment.DEVELOPMENT_ENVIRONMENT:
        firebaseOptions = {
          apiKey: "AIzaSyAZWo4O9MsELEGXhgbQX-zvUu7dzR1MJ4E",
          authDomain: "meshtrade-development.firebaseapp.com",
          projectId: "meshtrade-development",
          storageBucket: "meshtrade-development.appspot.com",
          messagingSenderId: "634995748095",
          appId: "1:634995748095:web:e6fff9b1efd652b9ec70ca",
        };
        break;

      case Environment.TESTING_ENVIRONMENT:
        firebaseOptions = {
          apiKey: "AIzaSyAufIxAKZQ-qs1CaJGXmhsmb8fx1dc_kKk",
          authDomain: "meshtrade-testing.firebaseapp.com",
          projectId: "meshtrade-testing",
          storageBucket: "meshtrade-testing.appspot.com",
          messagingSenderId: "1026566443938",
          appId: "1:1026566443938:web:1da5cfebd4fda77e6058fb",
        };
        break;

      case Environment.STAGING_ENVIRONMENT:
        firebaseOptions = {
          apiKey: "AIzaSyDIV381_ncYzz2oPhtE1Zaq_gpOIFMFrn4",
          authDomain: "meshtrade-staging.firebaseapp.com",
          projectId: "meshtrade-staging",
          storageBucket: "meshtrade-staging.appspot.com",
          messagingSenderId: "262164795701",
          appId: "1:262164795701:web:7357b2a0ea84648f207c7c",
        };
        break;

      case Environment.PRODUCTION_ENVIRONMENT:
        firebaseOptions = {
          apiKey: "AIzaSyA6AQEz6hJAB7nsJPHv24QfPOf0Lrz3rv0",
          authDomain: "meshtrade-production.firebaseapp.com",
          projectId: "meshtrade-production",
          storageBucket: "meshtrade-production.appspot.com",
          messagingSenderId: "343851239599",
          appId: "1:343851239599:web:df7e5db82ee2495d3aec5a",
          measurementId: "G-KQWRBLTPZN",
        };
        break;

      default:
        throw new Error(`unexpected environment: '${environment}'`);
    }
    console.debug(`using firebase project: '${firebaseOptions.projectId}'`);

    return firebaseOptions;
  }, [environment]);
  const app = useMemo(() => initializeApp(config), [config]);
  const auth = useMemo(() => getAuth(app), [app]);

  // indicate context loading state
  const [loading, setLoading] = useState(true);

  // user and access token
  const [firebaseUser, setFirebaseUser] = useState<User | null>(null);
  const [firebaseAccessToken, setFirebaseAccessToken] = useState("");

  // keep user state up to date
  useEffect(() => {
    auth.onAuthStateChanged(async (user: User | null) => {
      try {
        setFirebaseAccessToken(user ? await user.getIdToken() : "");
      } catch (e: unknown) {
        console.error("error getting firebase access token", e);
        return;
      }
      setFirebaseUser(user);
      setLoading(false);
    });
  }, [auth]);

  // sign in/out functions
  const signInWithGoogle = useCallback(() => {
    const provider = new GoogleAuthProvider();
    provider.setCustomParameters({
      prompt: "select_account",
    });
    signInWithRedirect(auth, provider);
  }, [auth]);
  const signOut = useCallback(() => {
    firebaseSignOut(auth);
  }, [auth]);

  return (
    <FirebaseContext.Provider
      value={{
        firebaseUser,
        firebaseAccessToken,
        firebaseLoading: loading,
        signInWithGoogle,
        signOut,
      }}
    >
      {children}
    </FirebaseContext.Provider>
  );
};

export const useFirebaseContext = () => useContext(FirebaseContext);
