import React, { useEffect } from "react";
import "./App.css";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import { HolidayCalendar } from "./views/HolidayCalendar";
import { InstrumentCalculator } from "./views/InstrumentCalculator";
import { PrimeRateCapturer } from "./views/PrimeRateCapturer";
import { Helmet } from "react-helmet-async";
import { MainAppBarLayout } from "./layouts/MainAppBar/MainAppBarLayout";
import { useFirebaseContext } from "./context/Firebase";
import backgroundCroppedDark from "./assets/images/background/backgroundCropped.jpeg";
import { Box, CircularProgress } from "@mui/material";
import { Login } from "./views/Login/Login";

function App() {
  const navigate = useNavigate();
  const { firebaseUser, firebaseLoading } = useFirebaseContext();

  useEffect(() => {
    if (!(firebaseUser || firebaseLoading)) {
      navigate("/login");
    }
  }, [firebaseUser]);

  if (firebaseLoading) {
    return (
      <Box
        sx={{
          minHeight: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundImage: `url(${backgroundCroppedDark})`,
          backgroundSize: "cover",
          position: "relative",
        }}
      >
        <CircularProgress size={50} />
      </Box>
    );
  }

  return (
    <div className="App">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Bond Calculator</title>
      </Helmet>
      <Routes>
        {firebaseUser ? (
          <>
            <Route
              element={
                <MainAppBarLayout
                  paths={[
                    {
                      name: "Holiday Calendar",
                      path: "/holiday-calendar",
                    },
                    {
                      name: "Prime Rate Capturer",
                      path: "/prime-rate-capturer",
                    },
                    {
                      name: "Instrument Calculator",
                      path: "/instrument-calculator",
                    },
                  ]}
                />
              }
            >
              <Route path="/holiday-calendar/*" element={<HolidayCalendar />} />
              <Route
                path="/prime-rate-capturer/*"
                element={<PrimeRateCapturer />}
              />
              <Route
                path="/instrument-calculator/*"
                element={<InstrumentCalculator />}
              />
            </Route>

            {/* remove end of given url and re-render - ultimately cascading to a blank route which will then match with the index element that navigates to a default route */}
            <Route path="/*" element={<Navigate to={""} />} />
            <Route index element={<Navigate to={"/instrument-calculator"} />} />
          </>
        ) : (
          <>
            <Route path="/login" element={<Login />} />

            {/* remove end of given url and re-render - ultimately cascading to a blank route which will then match with the index element that navigates to a default route */}
            <Route path="/*" element={<Navigate to={""} />} />
            <Route index element={<Navigate to={"/login"} />} />
          </>
        )}
      </Routes>
    </div>
  );
}

// eslint-disable-next-line import/no-default-export
export default App;
