// source: financial/fixedRateBondStub.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var ledger_amount_pb = require('../ledger/amount_pb.js');
goog.object.extend(proto, ledger_amount_pb);
var num_decimal_pb = require('../num/decimal_pb.js');
goog.object.extend(proto, num_decimal_pb);
var financial_couponFrequency_pb = require('../financial/couponFrequency_pb.js');
goog.object.extend(proto, financial_couponFrequency_pb);
var financial_dayCountConvention_pb = require('../financial/dayCountConvention_pb.js');
goog.object.extend(proto, financial_dayCountConvention_pb);
var financial_businessDayConvention_pb = require('../financial/businessDayConvention_pb.js');
goog.object.extend(proto, financial_businessDayConvention_pb);
var financial_calendar_pb = require('../financial/calendar_pb.js');
goog.object.extend(proto, financial_calendar_pb);
var financial_dateGenerationRule_pb = require('../financial/dateGenerationRule_pb.js');
goog.object.extend(proto, financial_dateGenerationRule_pb);
goog.exportSymbol('proto.financial.FixedRateBondStub', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.financial.FixedRateBondStub = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.financial.FixedRateBondStub, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.financial.FixedRateBondStub.displayName = 'proto.financial.FixedRateBondStub';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.financial.FixedRateBondStub.prototype.toObject = function(opt_includeInstance) {
  return proto.financial.FixedRateBondStub.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.financial.FixedRateBondStub} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.financial.FixedRateBondStub.toObject = function(includeInstance, msg) {
  var f, obj = {
    totalnominal: (f = msg.getTotalnominal()) && ledger_amount_pb.FutureAmount.toObject(includeInstance, f),
    redemption: (f = msg.getRedemption()) && num_decimal_pb.Decimal.toObject(includeInstance, f),
    issuedate: (f = msg.getIssuedate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    maturitydate: (f = msg.getMaturitydate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    calendar: jspb.Message.getFieldWithDefault(msg, 5, 0),
    couponpaymentfrequency: jspb.Message.getFieldWithDefault(msg, 6, 0),
    firstcouponpaymentdate: (f = msg.getFirstcouponpaymentdate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    nexttolastcouponpaymentdate: (f = msg.getNexttolastcouponpaymentdate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    couponinterestcommencementdate: (f = msg.getCouponinterestcommencementdate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    daycountconvention: jspb.Message.getFieldWithDefault(msg, 10, 0),
    endofmonthconvention: jspb.Message.getBooleanFieldWithDefault(msg, 11, false),
    businessdayconvention: jspb.Message.getFieldWithDefault(msg, 12, 0),
    terminationdatebusinessdayconvention: jspb.Message.getFieldWithDefault(msg, 13, 0),
    dategenerationrule: jspb.Message.getFieldWithDefault(msg, 14, 0),
    fixdays: jspb.Message.getFieldWithDefault(msg, 15, 0),
    excoupondays: jspb.Message.getFieldWithDefault(msg, 16, 0),
    recorddays: jspb.Message.getFieldWithDefault(msg, 17, 0),
    couponrate: (f = msg.getCouponrate()) && num_decimal_pb.Decimal.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.financial.FixedRateBondStub}
 */
proto.financial.FixedRateBondStub.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.financial.FixedRateBondStub;
  return proto.financial.FixedRateBondStub.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.financial.FixedRateBondStub} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.financial.FixedRateBondStub}
 */
proto.financial.FixedRateBondStub.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new ledger_amount_pb.FutureAmount;
      reader.readMessage(value,ledger_amount_pb.FutureAmount.deserializeBinaryFromReader);
      msg.setTotalnominal(value);
      break;
    case 2:
      var value = new num_decimal_pb.Decimal;
      reader.readMessage(value,num_decimal_pb.Decimal.deserializeBinaryFromReader);
      msg.setRedemption(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setIssuedate(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setMaturitydate(value);
      break;
    case 5:
      var value = /** @type {!proto.financial.Calendar} */ (reader.readEnum());
      msg.setCalendar(value);
      break;
    case 6:
      var value = /** @type {!proto.financial.CouponFrequency} */ (reader.readEnum());
      msg.setCouponpaymentfrequency(value);
      break;
    case 7:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setFirstcouponpaymentdate(value);
      break;
    case 8:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setNexttolastcouponpaymentdate(value);
      break;
    case 9:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCouponinterestcommencementdate(value);
      break;
    case 10:
      var value = /** @type {!proto.financial.DayCountConvention} */ (reader.readEnum());
      msg.setDaycountconvention(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEndofmonthconvention(value);
      break;
    case 12:
      var value = /** @type {!proto.financial.BusinessDayConvention} */ (reader.readEnum());
      msg.setBusinessdayconvention(value);
      break;
    case 13:
      var value = /** @type {!proto.financial.BusinessDayConvention} */ (reader.readEnum());
      msg.setTerminationdatebusinessdayconvention(value);
      break;
    case 14:
      var value = /** @type {!proto.financial.DateGenerationRule} */ (reader.readEnum());
      msg.setDategenerationrule(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setFixdays(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setExcoupondays(value);
      break;
    case 17:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setRecorddays(value);
      break;
    case 18:
      var value = new num_decimal_pb.Decimal;
      reader.readMessage(value,num_decimal_pb.Decimal.deserializeBinaryFromReader);
      msg.setCouponrate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.financial.FixedRateBondStub.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.financial.FixedRateBondStub.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.financial.FixedRateBondStub} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.financial.FixedRateBondStub.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTotalnominal();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      ledger_amount_pb.FutureAmount.serializeBinaryToWriter
    );
  }
  f = message.getRedemption();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      num_decimal_pb.Decimal.serializeBinaryToWriter
    );
  }
  f = message.getIssuedate();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getMaturitydate();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getCalendar();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getCouponpaymentfrequency();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getFirstcouponpaymentdate();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getNexttolastcouponpaymentdate();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getCouponinterestcommencementdate();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getDaycountconvention();
  if (f !== 0.0) {
    writer.writeEnum(
      10,
      f
    );
  }
  f = message.getEndofmonthconvention();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
  f = message.getBusinessdayconvention();
  if (f !== 0.0) {
    writer.writeEnum(
      12,
      f
    );
  }
  f = message.getTerminationdatebusinessdayconvention();
  if (f !== 0.0) {
    writer.writeEnum(
      13,
      f
    );
  }
  f = message.getDategenerationrule();
  if (f !== 0.0) {
    writer.writeEnum(
      14,
      f
    );
  }
  f = message.getFixdays();
  if (f !== 0) {
    writer.writeUint32(
      15,
      f
    );
  }
  f = message.getExcoupondays();
  if (f !== 0) {
    writer.writeUint32(
      16,
      f
    );
  }
  f = message.getRecorddays();
  if (f !== 0) {
    writer.writeUint32(
      17,
      f
    );
  }
  f = message.getCouponrate();
  if (f != null) {
    writer.writeMessage(
      18,
      f,
      num_decimal_pb.Decimal.serializeBinaryToWriter
    );
  }
};


/**
 * optional ledger.FutureAmount totalNominal = 1;
 * @return {?proto.ledger.FutureAmount}
 */
proto.financial.FixedRateBondStub.prototype.getTotalnominal = function() {
  return /** @type{?proto.ledger.FutureAmount} */ (
    jspb.Message.getWrapperField(this, ledger_amount_pb.FutureAmount, 1));
};


/**
 * @param {?proto.ledger.FutureAmount|undefined} value
 * @return {!proto.financial.FixedRateBondStub} returns this
*/
proto.financial.FixedRateBondStub.prototype.setTotalnominal = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.financial.FixedRateBondStub} returns this
 */
proto.financial.FixedRateBondStub.prototype.clearTotalnominal = function() {
  return this.setTotalnominal(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.financial.FixedRateBondStub.prototype.hasTotalnominal = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional num.Decimal redemption = 2;
 * @return {?proto.num.Decimal}
 */
proto.financial.FixedRateBondStub.prototype.getRedemption = function() {
  return /** @type{?proto.num.Decimal} */ (
    jspb.Message.getWrapperField(this, num_decimal_pb.Decimal, 2));
};


/**
 * @param {?proto.num.Decimal|undefined} value
 * @return {!proto.financial.FixedRateBondStub} returns this
*/
proto.financial.FixedRateBondStub.prototype.setRedemption = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.financial.FixedRateBondStub} returns this
 */
proto.financial.FixedRateBondStub.prototype.clearRedemption = function() {
  return this.setRedemption(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.financial.FixedRateBondStub.prototype.hasRedemption = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.Timestamp issueDate = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.financial.FixedRateBondStub.prototype.getIssuedate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.financial.FixedRateBondStub} returns this
*/
proto.financial.FixedRateBondStub.prototype.setIssuedate = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.financial.FixedRateBondStub} returns this
 */
proto.financial.FixedRateBondStub.prototype.clearIssuedate = function() {
  return this.setIssuedate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.financial.FixedRateBondStub.prototype.hasIssuedate = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.Timestamp maturityDate = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.financial.FixedRateBondStub.prototype.getMaturitydate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.financial.FixedRateBondStub} returns this
*/
proto.financial.FixedRateBondStub.prototype.setMaturitydate = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.financial.FixedRateBondStub} returns this
 */
proto.financial.FixedRateBondStub.prototype.clearMaturitydate = function() {
  return this.setMaturitydate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.financial.FixedRateBondStub.prototype.hasMaturitydate = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional Calendar calendar = 5;
 * @return {!proto.financial.Calendar}
 */
proto.financial.FixedRateBondStub.prototype.getCalendar = function() {
  return /** @type {!proto.financial.Calendar} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.financial.Calendar} value
 * @return {!proto.financial.FixedRateBondStub} returns this
 */
proto.financial.FixedRateBondStub.prototype.setCalendar = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional CouponFrequency couponPaymentFrequency = 6;
 * @return {!proto.financial.CouponFrequency}
 */
proto.financial.FixedRateBondStub.prototype.getCouponpaymentfrequency = function() {
  return /** @type {!proto.financial.CouponFrequency} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.financial.CouponFrequency} value
 * @return {!proto.financial.FixedRateBondStub} returns this
 */
proto.financial.FixedRateBondStub.prototype.setCouponpaymentfrequency = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional google.protobuf.Timestamp firstCouponPaymentDate = 7;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.financial.FixedRateBondStub.prototype.getFirstcouponpaymentdate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 7));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.financial.FixedRateBondStub} returns this
*/
proto.financial.FixedRateBondStub.prototype.setFirstcouponpaymentdate = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.financial.FixedRateBondStub} returns this
 */
proto.financial.FixedRateBondStub.prototype.clearFirstcouponpaymentdate = function() {
  return this.setFirstcouponpaymentdate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.financial.FixedRateBondStub.prototype.hasFirstcouponpaymentdate = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional google.protobuf.Timestamp nextToLastCouponPaymentDate = 8;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.financial.FixedRateBondStub.prototype.getNexttolastcouponpaymentdate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 8));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.financial.FixedRateBondStub} returns this
*/
proto.financial.FixedRateBondStub.prototype.setNexttolastcouponpaymentdate = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.financial.FixedRateBondStub} returns this
 */
proto.financial.FixedRateBondStub.prototype.clearNexttolastcouponpaymentdate = function() {
  return this.setNexttolastcouponpaymentdate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.financial.FixedRateBondStub.prototype.hasNexttolastcouponpaymentdate = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional google.protobuf.Timestamp couponInterestCommencementDate = 9;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.financial.FixedRateBondStub.prototype.getCouponinterestcommencementdate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 9));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.financial.FixedRateBondStub} returns this
*/
proto.financial.FixedRateBondStub.prototype.setCouponinterestcommencementdate = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.financial.FixedRateBondStub} returns this
 */
proto.financial.FixedRateBondStub.prototype.clearCouponinterestcommencementdate = function() {
  return this.setCouponinterestcommencementdate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.financial.FixedRateBondStub.prototype.hasCouponinterestcommencementdate = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional DayCountConvention dayCountConvention = 10;
 * @return {!proto.financial.DayCountConvention}
 */
proto.financial.FixedRateBondStub.prototype.getDaycountconvention = function() {
  return /** @type {!proto.financial.DayCountConvention} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {!proto.financial.DayCountConvention} value
 * @return {!proto.financial.FixedRateBondStub} returns this
 */
proto.financial.FixedRateBondStub.prototype.setDaycountconvention = function(value) {
  return jspb.Message.setProto3EnumField(this, 10, value);
};


/**
 * optional bool endOfMonthConvention = 11;
 * @return {boolean}
 */
proto.financial.FixedRateBondStub.prototype.getEndofmonthconvention = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.financial.FixedRateBondStub} returns this
 */
proto.financial.FixedRateBondStub.prototype.setEndofmonthconvention = function(value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};


/**
 * optional BusinessDayConvention businessDayConvention = 12;
 * @return {!proto.financial.BusinessDayConvention}
 */
proto.financial.FixedRateBondStub.prototype.getBusinessdayconvention = function() {
  return /** @type {!proto.financial.BusinessDayConvention} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {!proto.financial.BusinessDayConvention} value
 * @return {!proto.financial.FixedRateBondStub} returns this
 */
proto.financial.FixedRateBondStub.prototype.setBusinessdayconvention = function(value) {
  return jspb.Message.setProto3EnumField(this, 12, value);
};


/**
 * optional BusinessDayConvention terminationDateBusinessDayConvention = 13;
 * @return {!proto.financial.BusinessDayConvention}
 */
proto.financial.FixedRateBondStub.prototype.getTerminationdatebusinessdayconvention = function() {
  return /** @type {!proto.financial.BusinessDayConvention} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {!proto.financial.BusinessDayConvention} value
 * @return {!proto.financial.FixedRateBondStub} returns this
 */
proto.financial.FixedRateBondStub.prototype.setTerminationdatebusinessdayconvention = function(value) {
  return jspb.Message.setProto3EnumField(this, 13, value);
};


/**
 * optional DateGenerationRule dateGenerationRule = 14;
 * @return {!proto.financial.DateGenerationRule}
 */
proto.financial.FixedRateBondStub.prototype.getDategenerationrule = function() {
  return /** @type {!proto.financial.DateGenerationRule} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {!proto.financial.DateGenerationRule} value
 * @return {!proto.financial.FixedRateBondStub} returns this
 */
proto.financial.FixedRateBondStub.prototype.setDategenerationrule = function(value) {
  return jspb.Message.setProto3EnumField(this, 14, value);
};


/**
 * optional uint32 fixDays = 15;
 * @return {number}
 */
proto.financial.FixedRateBondStub.prototype.getFixdays = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {number} value
 * @return {!proto.financial.FixedRateBondStub} returns this
 */
proto.financial.FixedRateBondStub.prototype.setFixdays = function(value) {
  return jspb.Message.setProto3IntField(this, 15, value);
};


/**
 * optional uint32 exCouponDays = 16;
 * @return {number}
 */
proto.financial.FixedRateBondStub.prototype.getExcoupondays = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/**
 * @param {number} value
 * @return {!proto.financial.FixedRateBondStub} returns this
 */
proto.financial.FixedRateBondStub.prototype.setExcoupondays = function(value) {
  return jspb.Message.setProto3IntField(this, 16, value);
};


/**
 * optional uint32 recordDays = 17;
 * @return {number}
 */
proto.financial.FixedRateBondStub.prototype.getRecorddays = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 17, 0));
};


/**
 * @param {number} value
 * @return {!proto.financial.FixedRateBondStub} returns this
 */
proto.financial.FixedRateBondStub.prototype.setRecorddays = function(value) {
  return jspb.Message.setProto3IntField(this, 17, value);
};


/**
 * optional num.Decimal couponRate = 18;
 * @return {?proto.num.Decimal}
 */
proto.financial.FixedRateBondStub.prototype.getCouponrate = function() {
  return /** @type{?proto.num.Decimal} */ (
    jspb.Message.getWrapperField(this, num_decimal_pb.Decimal, 18));
};


/**
 * @param {?proto.num.Decimal|undefined} value
 * @return {!proto.financial.FixedRateBondStub} returns this
*/
proto.financial.FixedRateBondStub.prototype.setCouponrate = function(value) {
  return jspb.Message.setWrapperField(this, 18, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.financial.FixedRateBondStub} returns this
 */
proto.financial.FixedRateBondStub.prototype.clearCouponrate = function() {
  return this.setCouponrate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.financial.FixedRateBondStub.prototype.hasCouponrate = function() {
  return jspb.Message.getField(this, 18) != null;
};


goog.object.extend(exports, proto.financial);
