import { SearchPrimeRateRequest } from "@mesh/common-js/dist/financial/primeRateFetcher_pb";
import { PrimeRate } from "@mesh/common-js/dist/financial/primeRate_pb";
import React, { useState } from "react";
import { toast } from "react-toastify";
import { useAPIContext } from "../../../context/API";
import { Box, Button, CircularProgress, Typography } from "@mui/material";
import { protobufTimestampToDayjs } from "@mesh/common-js/dist/googleProtobufConverters";
import { decimalToBigNumber } from "@mesh/common-js/dist/num";
import { Decimal } from "@mesh/common-js/dist/num/decimal_pb";
import { Timestamp } from "google-protobuf/google/protobuf/timestamp_pb";
import { DateTimeField } from "@mesh/common-js-react/dist/FormFields";
import {
  DataTable,
  RowType,
} from "@mesh/common-js-react/dist/Tables/DataTable";
import { timezoneToString } from "@mesh/common-js/dist/i8n";
import { Timezone } from "@mesh/common-js/dist/i8n/timezone_pb";
import { Query } from "@mesh/common-js/dist/search/query_pb";
import { Sorting, SortingOrder } from "@mesh/common-js/dist/search/sorting_pb";

export type SearchPrimeRatesProps = {
  timezone: Timezone;
};

const defaultQuery = new Query();
defaultQuery.addSorting(
  new Sorting().setField("date").setOrder(SortingOrder.ASC_SORTING_ORDER),
);

export const SearchPrimeRates = (props: SearchPrimeRatesProps) => {
  const [fetchForm, setFetchForm] = useState(
    new SearchPrimeRateRequest().setQuery(defaultQuery),
  );
  const [primeRates, setPrimeRates] = useState<PrimeRate[]>();
  const [loading, setLoading] = useState(false);
  const { financial } = useAPIContext();

  const columns: RowType<PrimeRate, Omit<PrimeRate.AsObject, "id">> = {
    date: {
      title: "Date",
      renderCell: function (rowData: PrimeRate): React.ReactNode {
        return protobufTimestampToDayjs(rowData.getDate() ?? new Timestamp())
          .tz(timezoneToString(props.timezone))
          .format();
      },
    },
    rate: {
      title: "Rate",
      renderCell: function (rowData: PrimeRate): React.ReactNode {
        return decimalToBigNumber(
          rowData.getRate() ?? new Decimal(),
        ).toFormat();
      },
    },
  };

  const searchRate = async () => {
    setLoading(true);
    try {
      const response =
        await financial.primeRateFetcher.searchPrimeRate(fetchForm);
      setPrimeRates(response.getPrimeratesList());
    } catch (e) {
      toast.error(`Error Fetching Prime Rate: ${e}`, {
        position: "bottom-left",
      });
      setPrimeRates(undefined);
      setLoading(false);
      return;
    }
    setLoading(false);
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Box sx={{ display: "flex", alignItems: "center", py: 2, gap: 3 }}>
        <DateTimeField
          disabled={loading}
          label="Start Date"
          nullable
          value={fetchForm.getStartdate()}
          onChange={(newValue) =>
            setFetchForm(fetchForm.setStartdate(newValue))
          }
        />
        <DateTimeField
          disabled={loading}
          label="End Date"
          nullable
          value={fetchForm.getEnddate()}
          onChange={(newValue) => setFetchForm(fetchForm.setEnddate(newValue))}
        />
        <Button variant="contained" color="primary" onClick={searchRate}>
          Search Rates
        </Button>
      </Box>

      {loading && <CircularProgress />}

      {!(primeRates && primeRates.length) && !loading && (
        <Typography>Nothing to show</Typography>
      )}

      {primeRates && primeRates.length > 0 && (
        <DataTable data={primeRates} columns={columns} />
      )}
    </Box>
  );
};
