import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Timestamp } from "google-protobuf/google/protobuf/timestamp_pb";
import React, { useMemo, useState } from "react";
import {
  Upload as UploadIcon,
  FileOpen as FileIcon,
} from "@mui/icons-material";
import { toast } from "react-toastify";
import Papa from "papaparse";
import { PrimeRateStub } from "@mesh/common-js/dist/financial/primeRateStub_pb";
import {
  dayjsToProtobufTimestamp,
  protobufTimestampToDayjs,
} from "@mesh/common-js/dist/googleProtobufConverters";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import {
  bigNumberToDecimal,
  decimalToBigNumber,
  formatTextNum,
} from "@mesh/common-js/dist/num";
import BigNumber from "bignumber.js";
import {
  DataTable,
  RowType,
} from "@mesh/common-js-react/dist/Tables/DataTable";
import { timezoneToString } from "@mesh/common-js/dist/i8n";
import { Decimal } from "@mesh/common-js/dist/num/decimal_pb";
import { Timezone } from "@mesh/common-js/dist/i8n/timezone_pb";
import { useAPIContext } from "../../../context/API";
import { ReplacePrimeRatesRequest } from "@mesh/common-js/dist/financial/primeRateRecorder_pb";
dayjs.extend(utc);

const dateFormat = "YYYY/MM/DD HH:mm:ss";

export type ReplacePrimeRateDialogProps = {
  timezone: Timezone;
  closeDialog: () => void;
};

export const ReplacePrimeRateDialog = (props: ReplacePrimeRateDialogProps) => {
  const { financial } = useAPIContext();
  const [primeRateStubs, setPrimeRateStubs] = useState<PrimeRateStub[]>([]);

  const [loading, setLoading] = useState(false);
  const replaceRates = async () => {
    setLoading(true);
    try {
      const request = new ReplacePrimeRatesRequest();
      primeRateStubs.forEach((p) => request.addPrimeratestubs(p));
      await financial.primeRateRecorder.replacePrimeRates(request);
    } catch (e) {
      toast.error(`Error Replacing Prime Rates: ${e}`, {
        position: "bottom-left",
      });
      setLoading(false);
      return;
    }
    toast.success("Prime Rates Replaced", {
      position: "bottom-left",
    });
    setLoading(false);
  };

  const couponPaymentColumns: RowType<PrimeRateStub, PrimeRateStub.AsObject> =
    useMemo(
      () => ({
        date: {
          title: "Date",
          renderCell: (rowData: PrimeRateStub) => {
            return protobufTimestampToDayjs(
              rowData.getDate() ?? new Timestamp(),
            )
              .tz(timezoneToString(props.timezone))
              .format(dateFormat);
          },
        },
        rate: {
          title: "Rate",
          renderCell: (rowData: PrimeRateStub) => {
            return formatTextNum(
              decimalToBigNumber(rowData.getRate() ?? new Decimal()),
              { noDecimalPlaces: 7 },
            );
          },
        },
      }),
      [props.timezone],
    );

  return (
    <Dialog
      open
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle
        sx={(theme) => ({
          display: "flex",
          flexDirection: "row",
          gap: theme.spacing(1),
        })}
      >
        Replace Prime Rates
        {loading && <CircularProgress size={20} />}
        <IconButton
          sx={{ marginLeft: "auto" }}
          disabled={loading}
          size="small"
          aria-label="close"
          onClick={props.closeDialog}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent
        sx={(theme) => ({ padding: `${theme.spacing(3)} !important` })}
      >
        {primeRateStubs.length ? (
          <>
            <Typography>Rates to Upload</Typography>
            <DataTable
              height={500}
              data={primeRateStubs}
              columns={couponPaymentColumns}
            />
          </>
        ) : (
          <>
            <Typography>
              Upload a csv file with rates in it to replace all of the rates
              currently stored.
            </Typography>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <FileUploadButton
          disabled={loading}
          onLoad={(rateStubs) => setPrimeRateStubs(rateStubs)}
        />
        {!!primeRateStubs.length && (
          <Button
            id={"instruments-documents-firstUpload-button"}
            sx={{
              marginTop: "4px",
              minWidth: 60,
            }}
            color="primary"
            variant="contained"
            startIcon={
              <UploadIcon
                sx={{
                  fontSize: "25px !important",
                  marginLeft: (theme) => theme.spacing(1),
                }}
              />
            }
            disabled={loading}
            onClick={replaceRates}
          >
            Upload
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

const allowedDocumentTypes = ["text/csv"];

const allowedDocumentTypesExtensions = [".csv"];

type FileUploadButtonProps = {
  disabled: boolean;
  onLoad: (rateStubs: PrimeRateStub[]) => void;
};

const FileUploadButton = (props: FileUploadButtonProps) => {
  const fileInputRef = React.useRef<HTMLInputElement>(null);

  const handleButtonClick = () => {
    fileInputRef.current?.click();
  };

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];

      if (!allowedDocumentTypes.includes(file.type)) {
        toast.error(
          `File Type Not Supported. Should be one of: ${allowedDocumentTypes.join(
            ", ",
          )}`,
          {
            position: "bottom-left",
          },
        );
        return;
      }

      // Document size limit (e.g., 20MB)
      const maxSizeInBytes = 20 * 1024 * 1024; // 20MB
      if (file.size > maxSizeInBytes) {
        toast.error("File is Too Large for Upload (Max 20 MB)", {
          position: "bottom-left",
        });
        return;
      }

      // PapaParse configuration
      const config: Papa.ParseConfig = {
        complete: (results) => {
          const rates: PrimeRateStub[] = [];
          (results.data as { Dates: string; Rates: string }[]).forEach(
            (rate) => {
              if (rate.Dates === "" || rate.Rates === "") {
                return;
              }
              rates.push(
                new PrimeRateStub()
                  .setDate(
                    dayjsToProtobufTimestamp(
                      dayjs(rate.Dates, "YYYY/MM/DD").utc(true).set("h", -2),
                    ),
                  )
                  .setRate(
                    bigNumberToDecimal(
                      new BigNumber(rate.Rates.replace(",", ".")),
                    ),
                  ),
              );
            },
          );
          props.onLoad(rates);
        },
        header: true, // Set to true if the first row of CSV are headers
        skipEmptyLines: true,
      };

      // Parse the file
      Papa.parse(await file.text(), config);
    }
  };

  return (
    <>
      <input
        type="file"
        ref={fileInputRef}
        onChange={handleFileChange}
        style={{ display: "none" }}
        accept={allowedDocumentTypesExtensions.join(", ")}
      />
      <Button
        disabled={props.disabled}
        id={"instruments-documents-firstUpload-button"}
        variant={"outlined"}
        sx={{
          marginTop: "4px",
          minWidth: 60,
        }}
        startIcon={
          <FileIcon
            sx={{
              fontSize: "25px !important",
              marginLeft: (theme) => theme.spacing(1),
            }}
          />
        }
        onClick={handleButtonClick}
      >
        Select CSV
      </Button>
    </>
  );
};
