import { StyledEngineProvider, ThemeProvider } from "@mui/material";
import { defaultTheme } from "./theme";

declare module "@mui/material/styles" {
  interface Palette {
    custom: {
      midnight: string;
      spaceblue: string;
      cardInner: string;
      lavender: string;
      lavenderLight: string;
      grape: string;
      grapeLight: string;
      grapeDark: string;
      mildYellow: string;
      yellow: string;
      green: string;
      red: string;
      opaqueBackground: string;
      pantone: {
        _526: string;
        _513: string;
        _246: string;
        _1788: string;
        _1787: string;
        _1585: string;
        _1375: string;
        _1235: string;
        _611: string;
        _383: string;
        _353: string;
        _3255: string;
        _326: string;
        _632: string;
        _640: string;
      };
    };
  }

  interface PaletteOptions {
    custom: {
      midnight: string;
      spaceblue: string;
      cardInner: string;
      lavender: string;
      grape: string;
      grapeLight: string;
      grapeDark: string;
      mildYellow: string;
      lavenderLight: string;
      yellow: string;
      green: string;
      red: string;
      opaqueBackground: string;
      pantone: {
        _526: string;
        _513: string;
        _246: string;
        _1788: string;
        _1787: string;
        _1585: string;
        _1375: string;
        _1235: string;
        _611: string;
        _383: string;
        _353: string;
        _3255: string;
        _326: string;
        _632: string;
        _640: string;
      };
    };
  }

  interface TypeText {
    hint: string;
  }
}

export const MuiThemeContext = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={defaultTheme}>{children}</ThemeProvider>
    </StyledEngineProvider>
  );
};
