"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.environmentToString = void 0;
const environment_pb_1 = require("./environment_pb");
const environmentStringMapping = {
    [environment_pb_1.Environment.PRODUCTION_ENVIRONMENT]: "Production",
    [environment_pb_1.Environment.STAGING_ENVIRONMENT]: "Staging",
    [environment_pb_1.Environment.TESTING_ENVIRONMENT]: "Testing",
    [environment_pb_1.Environment.DEVELOPMENT_ENVIRONMENT]: "Development",
    [environment_pb_1.Environment.LOCAL_ENVIRONMENT]: "Local",
};
/**
 * Converts an environment enum value to string representation.
 * @param {Environment} env - The environment enum value to convert.
 * @returns {string} The string representation of the environment enum value.
 */
function environmentToString(env) {
    const strValue = environmentStringMapping[env];
    if (!strValue) {
        throw new TypeError(`invalid environment value: '${env}'`);
    }
    return strValue;
}
exports.environmentToString = environmentToString;
