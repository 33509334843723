import React, { useContext, useMemo } from "react";
import { FixedRateBondCalculatorPromiseClient } from "@mesh/common-js/dist/financial/fixedRateBondCalculator_grpc_web_pb";
import { FloatingRateBondCalculatorPromiseClient } from "@mesh/common-js/dist/financial/floatingRateBondCalculator_grpc_web_pb";
import { PrimeRateFetcherPromiseClient } from "@mesh/common-js/dist/financial/primeRateFetcher_grpc_web_pb";
import { PrimeRateRecorderPromiseClient } from "@mesh/common-js/dist/financial/primeRateRecorder_grpc_web_pb";
import { CalendarInspectorPromiseClient } from "@mesh/common-js/dist/financial/calendarInspector_grpc_web_pb";
import { useConfigContext } from "../Config";
import { useFirebaseContext } from "../Firebase";
import {
  UnaryInterceptor,
  Request,
  UnaryResponse,
  RpcError,
  StatusCode,
} from "grpc-web";

/**
 * `AuthInterceptor` is a class responsible for intercepting requests
 * and adding an authorization token to the request metadata.
 */
class AuthInterceptor implements UnaryInterceptor<unknown, unknown> {
  /** Holds the authorization token to be added to the request metadata. */
  private token: string;

  /** The email address of the user */
  private userEmailAddress: string;

  /**
   * Constructs an instance of the `AuthInterceptor` class.
   *
   * @param {string} token - The authorization token.
   */
  constructor(token: string, userEmailAddress: string) {
    this.token = token;
    this.userEmailAddress = userEmailAddress;
  }

  /**
   * Intercepts a request to add the authorization token to its metadata.
   *
   * @param {Object} request - The request object.
   * @param {Function} request.getMetadata - A function that returns the request's metadata.
   * @param {unknown} invoker - A function responsible for processing the request.
   *                            Expected to be of type `function`, but can be of any type.
   *
   * @returns {unknown} The result of the `invoker` function, if it is a function.
   *                    Otherwise, logs an error and doesn't return anything.
   */
  async intercept(
    request: Request<unknown, unknown>,
    invoker: (
      request: Request<unknown, unknown>,
    ) => Promise<UnaryResponse<unknown, unknown>>,
  ): Promise<UnaryResponse<unknown, unknown>> {
    if (this.token === "") {
      throw new Error("authorization token is not set");
    }

    console.debug(request.getMethodDescriptor().getName());

    const metadata = request.getMetadata();
    metadata.authorization = this.token;

    try {
      return await invoker(request);
    } catch (e: unknown) {
      console.error(
        `error performing request: ${request.getMethodDescriptor().getName()}`,
        e,
      );
      if (
        e instanceof RpcError &&
        e.code === StatusCode.UNAUTHENTICATED &&
        !this.userEmailAddress.includes("@meshtrade.co")
      ) {
        throw new Error(
          `"Permission denied. You need to log in with an approved @meshtrade.co email address. You are logged in with: ${this.userEmailAddress}"`,
        );
      }
      throw e;
    }
  }
}

export type API = {
  financial: {
    calendarInspector: CalendarInspectorPromiseClient;
    fixedRateBondCalculator: FixedRateBondCalculatorPromiseClient;
    floatingRateBondCalculator: FloatingRateBondCalculatorPromiseClient;
    primeRateFetcher: PrimeRateFetcherPromiseClient;
    primeRateRecorder: PrimeRateRecorderPromiseClient;
  };
};

const defaultContext: API = {
  financial: {
    calendarInspector: new CalendarInspectorPromiseClient(""),
    fixedRateBondCalculator: new FixedRateBondCalculatorPromiseClient(""),
    floatingRateBondCalculator: new FloatingRateBondCalculatorPromiseClient(""),
    primeRateFetcher: new PrimeRateFetcherPromiseClient(""),
    primeRateRecorder: new PrimeRateRecorderPromiseClient(""),
  },
};

export const APIContext = React.createContext<API>(defaultContext);

export const APIProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { apiURL } = useConfigContext();
  const { firebaseAccessToken, firebaseUser } = useFirebaseContext();

  const authInterceptor = useMemo<AuthInterceptor>(
    () => new AuthInterceptor(firebaseAccessToken, firebaseUser?.email ?? ""),
    [firebaseAccessToken],
  );
  const api = useMemo<API>(
    () => ({
      financial: {
        calendarInspector: new CalendarInspectorPromiseClient(apiURL, null, {
          unaryInterceptors: [authInterceptor],
        }),
        fixedRateBondCalculator: new FixedRateBondCalculatorPromiseClient(
          apiURL,
          null,
          { unaryInterceptors: [authInterceptor] },
        ),
        floatingRateBondCalculator: new FloatingRateBondCalculatorPromiseClient(
          apiURL,
          null,
          {
            unaryInterceptors: [authInterceptor],
          },
        ),
        primeRateFetcher: new PrimeRateFetcherPromiseClient(apiURL, null, {
          unaryInterceptors: [authInterceptor],
        }),
        primeRateRecorder: new PrimeRateRecorderPromiseClient(apiURL, null, {
          unaryInterceptors: [authInterceptor],
        }),
      },
    }),
    [apiURL, authInterceptor],
  );

  return <APIContext.Provider value={api}>{children}</APIContext.Provider>;
};

export const useAPIContext = () => useContext(APIContext);
