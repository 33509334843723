import React from "react";
import { Button, Card, CardContent, Typography, Box } from "@mui/material";
import backgroundCroppedDark from "../../assets/images/background/backgroundCropped.jpeg";
import { useFirebaseContext } from "../../context/Firebase";

export const Login: () => JSX.Element = () => {
  const { signInWithGoogle } = useFirebaseContext();
  return (
    <Box
      sx={{
        minHeight: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundImage: `url(${backgroundCroppedDark})`,
        backgroundSize: "cover",
        position: "relative",
      }}
    >
      <Card
        sx={{
          width: 300,
          padding: 4,
          textAlign: "center",
          boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.15)",
        }}
      >
        <CardContent>
          <Typography sx={{ marginBottom: 4 }} variant="h5">
            Please sign in
          </Typography>
          <Button onClick={signInWithGoogle} variant="outlined">
            Sign in with Google
          </Button>
        </CardContent>
      </Card>
    </Box>
  );
};
