/**
 * @fileoverview gRPC-Web generated client stub for financial
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v3.21.12
// source: financial/calendarInspector.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var financial_calendar_pb = require('../financial/calendar_pb.js')

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js')
const proto = {};
proto.financial = require('./calendarInspector_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.financial.CalendarInspectorClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.financial.CalendarInspectorPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.financial.CheckIsBusinessDayRequest,
 *   !proto.financial.CheckIsBusinessDayResponse>}
 */
const methodDescriptor_CalendarInspector_CheckIsBusinessDay = new grpc.web.MethodDescriptor(
  '/financial.CalendarInspector/CheckIsBusinessDay',
  grpc.web.MethodType.UNARY,
  proto.financial.CheckIsBusinessDayRequest,
  proto.financial.CheckIsBusinessDayResponse,
  /**
   * @param {!proto.financial.CheckIsBusinessDayRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.financial.CheckIsBusinessDayResponse.deserializeBinary
);


/**
 * @param {!proto.financial.CheckIsBusinessDayRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.financial.CheckIsBusinessDayResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.financial.CheckIsBusinessDayResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.financial.CalendarInspectorClient.prototype.checkIsBusinessDay =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/financial.CalendarInspector/CheckIsBusinessDay',
      request,
      metadata || {},
      methodDescriptor_CalendarInspector_CheckIsBusinessDay,
      callback);
};


/**
 * @param {!proto.financial.CheckIsBusinessDayRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.financial.CheckIsBusinessDayResponse>}
 *     Promise that resolves to the response
 */
proto.financial.CalendarInspectorPromiseClient.prototype.checkIsBusinessDay =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/financial.CalendarInspector/CheckIsBusinessDay',
      request,
      metadata || {},
      methodDescriptor_CalendarInspector_CheckIsBusinessDay);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.financial.GetHolidaysRequest,
 *   !proto.financial.GetHolidaysResponse>}
 */
const methodDescriptor_CalendarInspector_GetHolidays = new grpc.web.MethodDescriptor(
  '/financial.CalendarInspector/GetHolidays',
  grpc.web.MethodType.UNARY,
  proto.financial.GetHolidaysRequest,
  proto.financial.GetHolidaysResponse,
  /**
   * @param {!proto.financial.GetHolidaysRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.financial.GetHolidaysResponse.deserializeBinary
);


/**
 * @param {!proto.financial.GetHolidaysRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.financial.GetHolidaysResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.financial.GetHolidaysResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.financial.CalendarInspectorClient.prototype.getHolidays =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/financial.CalendarInspector/GetHolidays',
      request,
      metadata || {},
      methodDescriptor_CalendarInspector_GetHolidays,
      callback);
};


/**
 * @param {!proto.financial.GetHolidaysRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.financial.GetHolidaysResponse>}
 *     Promise that resolves to the response
 */
proto.financial.CalendarInspectorPromiseClient.prototype.getHolidays =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/financial.CalendarInspector/GetHolidays',
      request,
      metadata || {},
      methodDescriptor_CalendarInspector_GetHolidays);
};


module.exports = proto.financial;

