import React, { useContext } from "react";
import { Environment } from "@mesh/common-js/dist/environment/environment_pb";
import { environmentToString } from "@mesh/common-js/dist/environment";

function determineEnvironment(): Environment {
  // Get the hostname from window.location
  const hostname = window.location.hostname;

  // Split the hostname by '.' to get the subdomains
  const subdomains = hostname.split(".");

  // Check the first subdomain to determine the environment
  const firstSubdomain = subdomains[0];

  // Map subdomains to environments
  switch (firstSubdomain) {
    case "production-bond-calculation-engine-ui":
      return Environment.PRODUCTION_ENVIRONMENT;
    case "staging-bond-calculation-engine-ui":
      return Environment.STAGING_ENVIRONMENT;
    case "testing-bond-calculation-engine-ui":
      return Environment.TESTING_ENVIRONMENT;
    case "development-bond-calculation-engine-ui":
      return Environment.DEVELOPMENT_ENVIRONMENT;
    default:
      return Environment.LOCAL_ENVIRONMENT;
  }
}

export type Config = {
  environment: Environment;
  apiURL: string;
};

const defaultContext: Config = {
  environment: Environment.LOCAL_ENVIRONMENT,
  apiURL: "",
};

export const ConfigContext = React.createContext<Config>(defaultContext);

export const ConfigProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const environment = determineEnvironment();
  console.debug(`using environment: ${environmentToString(environment)}`);

  let config: Config = defaultContext;
  switch (environment) {
    case Environment.PRODUCTION_ENVIRONMENT:
      config = {
        environment,
        apiURL: "https://production-envoy-proxy-app.mesh.trade",
      };
      break;

    case Environment.STAGING_ENVIRONMENT:
      config = {
        environment,
        apiURL: "https://staging-envoy-proxy-app.mesh.trade",
      };
      break;

    case Environment.TESTING_ENVIRONMENT:
      config = {
        environment,
        apiURL: "https://testing-envoy-proxy-app.mesh.trade",
      };
      break;

    case Environment.DEVELOPMENT_ENVIRONMENT:
      config = {
        environment,
        apiURL: "https://development-envoy-proxy-app.mesh.trade",
      };
      break;

    case Environment.LOCAL_ENVIRONMENT:
      config = {
        environment,
        apiURL: "http://localhost:9010",
      };
      break;
  }

  return (
    <ConfigContext.Provider value={config}>{children}</ConfigContext.Provider>
  );
};

export const useConfigContext = () => useContext(ConfigContext);
