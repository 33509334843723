// source: financial/fixedRateBondCalculator.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var financial_fixedRateBondStub_pb = require('../financial/fixedRateBondStub_pb.js');
goog.object.extend(proto, financial_fixedRateBondStub_pb);
var financial_couponPaymentStub_pb = require('../financial/couponPaymentStub_pb.js');
goog.object.extend(proto, financial_couponPaymentStub_pb);
var financial_maturityPaymentStub_pb = require('../financial/maturityPaymentStub_pb.js');
goog.object.extend(proto, financial_maturityPaymentStub_pb);
var ledger_amount_pb = require('../ledger/amount_pb.js');
goog.object.extend(proto, ledger_amount_pb);
goog.exportSymbol('proto.financial.CalculateFixedRateBondRequest', null, global);
goog.exportSymbol('proto.financial.CalculateFixedRateBondResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.financial.CalculateFixedRateBondRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.financial.CalculateFixedRateBondRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.financial.CalculateFixedRateBondRequest.displayName = 'proto.financial.CalculateFixedRateBondRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.financial.CalculateFixedRateBondResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.financial.CalculateFixedRateBondResponse.repeatedFields_, null);
};
goog.inherits(proto.financial.CalculateFixedRateBondResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.financial.CalculateFixedRateBondResponse.displayName = 'proto.financial.CalculateFixedRateBondResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.financial.CalculateFixedRateBondRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.financial.CalculateFixedRateBondRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.financial.CalculateFixedRateBondRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.financial.CalculateFixedRateBondRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    fixedratebondstub: (f = msg.getFixedratebondstub()) && financial_fixedRateBondStub_pb.FixedRateBondStub.toObject(includeInstance, f),
    evaluationdate: (f = msg.getEvaluationdate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.financial.CalculateFixedRateBondRequest}
 */
proto.financial.CalculateFixedRateBondRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.financial.CalculateFixedRateBondRequest;
  return proto.financial.CalculateFixedRateBondRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.financial.CalculateFixedRateBondRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.financial.CalculateFixedRateBondRequest}
 */
proto.financial.CalculateFixedRateBondRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new financial_fixedRateBondStub_pb.FixedRateBondStub;
      reader.readMessage(value,financial_fixedRateBondStub_pb.FixedRateBondStub.deserializeBinaryFromReader);
      msg.setFixedratebondstub(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setEvaluationdate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.financial.CalculateFixedRateBondRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.financial.CalculateFixedRateBondRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.financial.CalculateFixedRateBondRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.financial.CalculateFixedRateBondRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFixedratebondstub();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      financial_fixedRateBondStub_pb.FixedRateBondStub.serializeBinaryToWriter
    );
  }
  f = message.getEvaluationdate();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional FixedRateBondStub fixedRateBondStub = 1;
 * @return {?proto.financial.FixedRateBondStub}
 */
proto.financial.CalculateFixedRateBondRequest.prototype.getFixedratebondstub = function() {
  return /** @type{?proto.financial.FixedRateBondStub} */ (
    jspb.Message.getWrapperField(this, financial_fixedRateBondStub_pb.FixedRateBondStub, 1));
};


/**
 * @param {?proto.financial.FixedRateBondStub|undefined} value
 * @return {!proto.financial.CalculateFixedRateBondRequest} returns this
*/
proto.financial.CalculateFixedRateBondRequest.prototype.setFixedratebondstub = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.financial.CalculateFixedRateBondRequest} returns this
 */
proto.financial.CalculateFixedRateBondRequest.prototype.clearFixedratebondstub = function() {
  return this.setFixedratebondstub(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.financial.CalculateFixedRateBondRequest.prototype.hasFixedratebondstub = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.Timestamp evaluationDate = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.financial.CalculateFixedRateBondRequest.prototype.getEvaluationdate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.financial.CalculateFixedRateBondRequest} returns this
*/
proto.financial.CalculateFixedRateBondRequest.prototype.setEvaluationdate = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.financial.CalculateFixedRateBondRequest} returns this
 */
proto.financial.CalculateFixedRateBondRequest.prototype.clearEvaluationdate = function() {
  return this.setEvaluationdate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.financial.CalculateFixedRateBondRequest.prototype.hasEvaluationdate = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.financial.CalculateFixedRateBondResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.financial.CalculateFixedRateBondResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.financial.CalculateFixedRateBondResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.financial.CalculateFixedRateBondResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.financial.CalculateFixedRateBondResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    couponpaymentsList: jspb.Message.toObjectList(msg.getCouponpaymentsList(),
    financial_couponPaymentStub_pb.CouponPaymentStub.toObject, includeInstance),
    maturitypayment: (f = msg.getMaturitypayment()) && financial_maturityPaymentStub_pb.MaturityPaymentStub.toObject(includeInstance, f),
    cleanprice: (f = msg.getCleanprice()) && ledger_amount_pb.FutureAmount.toObject(includeInstance, f),
    dirtyprice: (f = msg.getDirtyprice()) && ledger_amount_pb.FutureAmount.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.financial.CalculateFixedRateBondResponse}
 */
proto.financial.CalculateFixedRateBondResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.financial.CalculateFixedRateBondResponse;
  return proto.financial.CalculateFixedRateBondResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.financial.CalculateFixedRateBondResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.financial.CalculateFixedRateBondResponse}
 */
proto.financial.CalculateFixedRateBondResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new financial_couponPaymentStub_pb.CouponPaymentStub;
      reader.readMessage(value,financial_couponPaymentStub_pb.CouponPaymentStub.deserializeBinaryFromReader);
      msg.addCouponpayments(value);
      break;
    case 2:
      var value = new financial_maturityPaymentStub_pb.MaturityPaymentStub;
      reader.readMessage(value,financial_maturityPaymentStub_pb.MaturityPaymentStub.deserializeBinaryFromReader);
      msg.setMaturitypayment(value);
      break;
    case 3:
      var value = new ledger_amount_pb.FutureAmount;
      reader.readMessage(value,ledger_amount_pb.FutureAmount.deserializeBinaryFromReader);
      msg.setCleanprice(value);
      break;
    case 4:
      var value = new ledger_amount_pb.FutureAmount;
      reader.readMessage(value,ledger_amount_pb.FutureAmount.deserializeBinaryFromReader);
      msg.setDirtyprice(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.financial.CalculateFixedRateBondResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.financial.CalculateFixedRateBondResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.financial.CalculateFixedRateBondResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.financial.CalculateFixedRateBondResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCouponpaymentsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      financial_couponPaymentStub_pb.CouponPaymentStub.serializeBinaryToWriter
    );
  }
  f = message.getMaturitypayment();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      financial_maturityPaymentStub_pb.MaturityPaymentStub.serializeBinaryToWriter
    );
  }
  f = message.getCleanprice();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      ledger_amount_pb.FutureAmount.serializeBinaryToWriter
    );
  }
  f = message.getDirtyprice();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      ledger_amount_pb.FutureAmount.serializeBinaryToWriter
    );
  }
};


/**
 * repeated CouponPaymentStub couponPayments = 1;
 * @return {!Array<!proto.financial.CouponPaymentStub>}
 */
proto.financial.CalculateFixedRateBondResponse.prototype.getCouponpaymentsList = function() {
  return /** @type{!Array<!proto.financial.CouponPaymentStub>} */ (
    jspb.Message.getRepeatedWrapperField(this, financial_couponPaymentStub_pb.CouponPaymentStub, 1));
};


/**
 * @param {!Array<!proto.financial.CouponPaymentStub>} value
 * @return {!proto.financial.CalculateFixedRateBondResponse} returns this
*/
proto.financial.CalculateFixedRateBondResponse.prototype.setCouponpaymentsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.financial.CouponPaymentStub=} opt_value
 * @param {number=} opt_index
 * @return {!proto.financial.CouponPaymentStub}
 */
proto.financial.CalculateFixedRateBondResponse.prototype.addCouponpayments = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.financial.CouponPaymentStub, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.financial.CalculateFixedRateBondResponse} returns this
 */
proto.financial.CalculateFixedRateBondResponse.prototype.clearCouponpaymentsList = function() {
  return this.setCouponpaymentsList([]);
};


/**
 * optional MaturityPaymentStub maturityPayment = 2;
 * @return {?proto.financial.MaturityPaymentStub}
 */
proto.financial.CalculateFixedRateBondResponse.prototype.getMaturitypayment = function() {
  return /** @type{?proto.financial.MaturityPaymentStub} */ (
    jspb.Message.getWrapperField(this, financial_maturityPaymentStub_pb.MaturityPaymentStub, 2));
};


/**
 * @param {?proto.financial.MaturityPaymentStub|undefined} value
 * @return {!proto.financial.CalculateFixedRateBondResponse} returns this
*/
proto.financial.CalculateFixedRateBondResponse.prototype.setMaturitypayment = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.financial.CalculateFixedRateBondResponse} returns this
 */
proto.financial.CalculateFixedRateBondResponse.prototype.clearMaturitypayment = function() {
  return this.setMaturitypayment(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.financial.CalculateFixedRateBondResponse.prototype.hasMaturitypayment = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional ledger.FutureAmount cleanPrice = 3;
 * @return {?proto.ledger.FutureAmount}
 */
proto.financial.CalculateFixedRateBondResponse.prototype.getCleanprice = function() {
  return /** @type{?proto.ledger.FutureAmount} */ (
    jspb.Message.getWrapperField(this, ledger_amount_pb.FutureAmount, 3));
};


/**
 * @param {?proto.ledger.FutureAmount|undefined} value
 * @return {!proto.financial.CalculateFixedRateBondResponse} returns this
*/
proto.financial.CalculateFixedRateBondResponse.prototype.setCleanprice = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.financial.CalculateFixedRateBondResponse} returns this
 */
proto.financial.CalculateFixedRateBondResponse.prototype.clearCleanprice = function() {
  return this.setCleanprice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.financial.CalculateFixedRateBondResponse.prototype.hasCleanprice = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional ledger.FutureAmount dirtyPrice = 4;
 * @return {?proto.ledger.FutureAmount}
 */
proto.financial.CalculateFixedRateBondResponse.prototype.getDirtyprice = function() {
  return /** @type{?proto.ledger.FutureAmount} */ (
    jspb.Message.getWrapperField(this, ledger_amount_pb.FutureAmount, 4));
};


/**
 * @param {?proto.ledger.FutureAmount|undefined} value
 * @return {!proto.financial.CalculateFixedRateBondResponse} returns this
*/
proto.financial.CalculateFixedRateBondResponse.prototype.setDirtyprice = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.financial.CalculateFixedRateBondResponse} returns this
 */
proto.financial.CalculateFixedRateBondResponse.prototype.clearDirtyprice = function() {
  return this.setDirtyprice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.financial.CalculateFixedRateBondResponse.prototype.hasDirtyprice = function() {
  return jspb.Message.getField(this, 4) != null;
};


goog.object.extend(exports, proto.financial);
